import { useMemo, useState } from 'react'
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableContainer,
	Typography
} from '@material-ui/core'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const useStyles = makeStyles(() => ({
	iconColor: {
		'&.MuiIconButton-root': {
			color: '#1976d2'
		}
	},

	tableRow1: {
		'&.MuiTableRow-root': {
			backgroundColor: '#242C59',

			'&:hover': {
				backgroundColor: '#242C5990'
			}
		}
	},
	tableRow2: {
		'&.MuiTableRow-root': {
			backgroundColor: '#2D376D',

			'&:hover': {
				backgroundColor: '#2D376D70'
			}
		}
	},
	tableHeadTypography: {
		'&.MuiTableCell-root': {
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: '31px',
			color: '#E6E1E5'
		}
	}
}))

const DisconnectedSensorsTable = (props: any) => {
	// const [limit, setLimit] = useState<number>(10)
	const limit = 10
	const [sortOrder, setSortOrder] = useState<number>(1)
	const [currentColumn, setCurrentColumn] = useState<string>('')
	const [page, setPage] = useState<number>(0)
	const classes = useStyles()

	const columns = [
		'Asset Area',
		'Asset Group',
		'Asset Name',
		'Sensors',
		'Sensors Code',
		'Electrical Number'
	]

	const applyPagination = useMemo(
		() => (sensors: any, page: number, rowsPerPage: number) =>
			sensors.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
		[]
	)

	const handlePageChange = (event: any, newPage: number) => {
		setPage(newPage)
	}

	// const handleLimitChange = (event: any) => {
	// 	setPage(0)
	// 	setLimit(parseInt(event.target.value, 10))
	// }

	const handleSortClick = (column: string) => {
		let key: string
		switch (column) {
			case 'Asset Area':
				key = 'asset_area'
				break
			case 'Asset Group':
				key = 'asset_group'
				break
			case 'Asset Name':
				key = 'asset_name'
				break
			case 'Electrical Number':
				key = 'electrical_number'
				break
			default:
				key = 'sensors'
				break
		}

		const sortedDisconnectedSensors = [...props.disConnectedSensorsList].sort(
			(a: any, b: any) =>
				sortOrder === 1
					? a[key].toLowerCase() > b[key].toLowerCase()
						? 1
						: -1
					: a[key].toLowerCase() < b[key].toLowerCase()
						? 1
						: -1
		)
		setCurrentColumn(column)
		setSortOrder(sortOrder === 1 ? -1 : 1)
		props.setDisConnectedSensorsList(sortedDisconnectedSensors)
	}

	return (
		<Stack
			style={{
				padding: '5px 30px'
			}}
		>
			<TableContainer
				sx={{
					// maxHeight: 400,
					overflow: 'auto'
				}}
			>
				<Table>
					<TableHead>
						<TableRow>
							{columns.map((column: any) => {
								return (
									<TableCell
										className={classes.tableHeadTypography}
										key={column}
									>
										{column}
										<IconButton
											data-testid='filter-button'
											size='small'
											className={classes.iconColor}
											onClick={() => handleSortClick(column)}
										>
											{sortOrder === 1 && column === currentColumn ? (
												<ArrowDropUpIcon fontSize='small' />
											) : (
												<ArrowDropDownIcon fontSize='small' />
											)}
										</IconButton>
									</TableCell>
								)
							})}
						</TableRow>
					</TableHead>

					<TableBody sx={{ width: '100%' }}>
						{props.disConnectedSensorsList?.length > 0 ? (
							applyPagination(
								props.disConnectedSensorsList.sort((a: any, b: any) => a?.asset_area.localeCompare(b?.asset_area)),
								page,
								limit
							)?.map((sensor: any, index: number) => (
								<TableRow
									key={sensor?.id}
									className={
										index % 2 === 0 ? classes.tableRow1 : classes.tableRow2
									}
								>
									<TableCell>{sensor.asset_area}</TableCell>
									<TableCell>{sensor.asset_group}</TableCell>
									<TableCell>{sensor.asset_name}</TableCell>
									<TableCell>{sensor.sensors}</TableCell>
									<TableCell>{sensor.sensor_code}</TableCell>
									<TableCell>{sensor.electrical_number}</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns?.length} align='center'>
									<Typography
										variant='h3'
										sx={{
											margin: '20px 0 0 10px',
											textAlign: 'center',
											width: '100%',
											display: 'block'
										}}
									>
										No Sensors Found
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* pagination */}
			{props.disConnectedSensorsList?.length && (
				// <Box>
				<TablePagination
					labelRowsPerPage=''
					component='div'
					count={props.disConnectedSensorsList.length}
					onPageChange={handlePageChange}
					// onRowsPerPageChange={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					sx={{
						'& .MuiSelect-select': {
							display: 'none !important'
						},
						'& > div.MuiToolbar-root > div.MuiInputBase-root > svg': {
							display: 'none !important'
						},
						justifyContent: 'flex-end'
					}}
				/>
				// </Box>
			)}
		</Stack>
	)
}

export default DisconnectedSensorsTable
