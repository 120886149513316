import { Typography } from "@mui/material";
import moment from "moment";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../../../componets/DataTable";

const NpiDowmtimeTickets = (props: any): ReactElement => {
  const navigate = useNavigate();
  const [npiDowntimeTikcets, setNpiDowntimeTikcets] = useState([]);

  const dispatch = useDispatch();
  const npiDowntimeTableColumns = [
    {
      label: "Ticket ID",
      key: "dt_ticket_alias",
      dbColumnName: "dt_ticket_alias"
    },
    {
      label: "Type",
      key: "dt_ticket_type",
      dbColumnName: "dt_ticket_type"
    },
    {
      label: "Created on",
      key: "created_on",
      dbColumnName: "created_on"
    },
    {
      label: "Assigned To",
      key: "assignee_name",
      dbColumnName: "assignee_name"
    },
    {
      label: "Priority",
      key: "dt_ticket_priority",
      dbColumnName: "dt_ticket_priority"
    },
    {
      label: "Ticket Status",
      key: "dt_ticket_status",
      dbColumnName: "dt_ticket_status"
    },
    {
      label: "Req. Downtime Start",
      key: "dt_start_date",
      dbColumnName: "dt_start_date"
    },
    {
      label: "Req. Downtime End",
      key: "dt_end_date",
      dbColumnName: "dt_end_date"
    },
    {
      label: "Conf. Downtime Start MP",
      key: "master_planner_confirmed_timestamp",
      dbColumnName: "master_planner_confirmed_timestamp"
    },
    {
      label: "Conf. Downtime Start FG",
      key: "finished_goods_planner_confirmed_timestamp",
      dbColumnName: "finished_goods_planner_confirmed_timestamp"
    }
  ];

  if (props?.ticket_category === "PD" || props?.ticket_category === "OH") {
    npiDowntimeTableColumns.push({
      label: "Conf. Downtime Start BP",
      key: "brand_planner_confirmed_timestamp",
      dbColumnName: "brand_planner_confirmed_timestamp"
    });
  }

  if (props?.ticket_category === "OH") {
    npiDowntimeTableColumns.splice(3, 0, {
      label: "Vendor",
      key: "dt_vendor",
      dbColumnName: "dt_vendor"
    });
    npiDowntimeTableColumns.splice(4, 0, {
      label: "Category",
      key: "dt_ticket_category",
      dbColumnName: "dt_ticket_category"
    });
  }

  useEffect(() => {
    if (props?.tickets?.length > 0) {
      setNpiDowntimeTikcets(
        props?.tickets?.map((item: any) => {
          return {
            ...item,
            dt_ticket_priority: renderPriorityText(item.dt_ticket_priority),
            dt_ticket_status: renderTicketStatusText(
              getTicketStatusShortText(item.dt_ticket_status)
            ),
            ticket_priority: item.dt_ticket_priority,
            ticket_status: item.dt_ticket_status,
            created_on: `${moment(item.created_on).format(
              "MM/DD/YYYY"
            )}, ${moment(item.created_on).format("hh:mm A")} PST`,
            dt_end_date: moment(item.dt_end_date.split("T")[0]).format(
              "MM/DD/YYYY"
            ),
            dt_start_date: moment(item.dt_start_date.split("T")[0]).format(
              "MM/DD/YYYY"
            ),
            master_planner_confirmed_timestamp: item?.master_planner
              ?.master_planner_confirmed_timestamp
              ? moment(
                  item?.master_planner?.master_planner_confirmed_timestamp
                ).format("MM/DD/YYYY")
              : "-",
            finished_goods_planner_confirmed_timestamp: item
              ?.finished_goods_planner
              ?.finished_goods_planner_confirmed_timestamp
              ? moment(
                  item?.finished_goods_planner
                    ?.finished_goods_planner_confirmed_timestamp
                ).format("MM/DD/YYYY")
              : "-",
            assignee_name: item?.assignee_name ?? "-",
            brand_planner_confirmed_timestamp: item?.brand_planner
              ?.brand_planner_confirmed_timestamp
              ? moment(
                  item?.brand_planner?.brand_planner_confirmed_timestamp
                ).format("MM/DD/YYYY")
              : "-",
            dt_vendor: item?.dt_vendor ?? "-",
            dt_ticket_category: item?.dt_ticket_category ?? "-",
          };
        })
      );
    } else {
      setNpiDowntimeTikcets([]);
    }
  }, [props?.tickets]);

  const renderTicketStatusText = (text: string): React.ReactElement => {
    const stylesObject: any = {
      minWidth: "98px",
      padding: "1.5px 10px",
      fontSize: "14px",
      fontWeight: 500,
      // maxWidth: "fit-content",
      maxWidth: "120px",
      borderRadius: "20px",
      textAlign: "center"
    };
    if (text.toLocaleLowerCase() === "closed") {
      stylesObject.background = "#1DAC56";
    } else if (text.toLocaleLowerCase() === "open") {
      stylesObject.background = "#F00";
    } else if (text.toLocaleLowerCase() === "in progress") {
      stylesObject.background = "#FF0";
      stylesObject.color = `#000`;
    } else if (text.toLocaleLowerCase() === "tbd") {
      stylesObject.background = "#FF8A65";
    } else if (text.toLocaleLowerCase() === "confirmed") {
      stylesObject.background = "transparent";
      stylesObject.border = "0.5px solid #FFF";
    } else if (text.toLocaleLowerCase() === "mp confirmed") {
      stylesObject.background = "#FF0";
      stylesObject.color = `#000`;
    } else if (text.toLocaleLowerCase() === "fg confirmed") {
      stylesObject.background = "#FF0";
      stylesObject.color = `#000`;
    } else if (
      text.toLocaleLowerCase() === "rejected" ||
      text.toLocaleLowerCase() === "escalate" ||
      text.toLocaleLowerCase() === "bp confirmed"
    ) {
      stylesObject.background = "#FF0";
      stylesObject.color = `#000`;
    }
    return (
      <Typography variant="body1" style={{ ...stylesObject }}>
        {text}
      </Typography>
    );
  };

  const renderPriorityText = (text: string): React.ReactElement => {
    const stylesObject = {
      color: "#FFF"
    };
    if (text.toLocaleLowerCase() === "high") {
      stylesObject.color = "#F00";
    } else if (text.toLocaleLowerCase() === "low") {
      stylesObject.color = "#FFF";
    } else if (text.toLocaleLowerCase() === "medium") {
      stylesObject.color = "#FF0";
    }
    return <Typography style={{ ...stylesObject }}>{text}</Typography>;
  };

  const getTicketStatusShortText = (text: string): string => {
    const ticketStatus = text;
    if (ticketStatus.toLocaleLowerCase() === "master planner confirmed") {
      return "MP Confirmed";
    } else if (
      ticketStatus.toLocaleLowerCase() === "finished goods planner confirmed"
    ) {
      return "FG Confirmed";
    } else if (ticketStatus.toLocaleLowerCase() === "brand planner confirmed") {
      return "BP Confirmed";
    } else if (ticketStatus.toLocaleLowerCase() === "reject") {
      return "Rejected";
    }

    return ticketStatus;
  };

  return (
    <DataTable
      columns={npiDowntimeTableColumns}
      data={npiDowntimeTikcets}
      loading={props?.loading}
      pagination={npiDowntimeTikcets.length > 0 ? true : false}
      defaultEmptyMessage={"No Tickets"}
      paginationOptions={{
        count: props?.totalCount,
        onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>): void => {
          if (typeof props.handleLimitChange === "function") {
            props.handleLimitChange(event);
          }
        },
        onPageChange: (event: any, newPage: number): void => {
          if (typeof props.handlePageChange === "function") {
            props.handlePageChange(event, newPage);
          }
        },
        page: props?.page,
        rowsPerPage: props?.limit,
        rowsPerPageOptions: [5, 10, 25, 50]
      }}
      sorting={true}
      handleSort={(filters: any) => {
        if (typeof props.handleColumsSort === "function") {
          props.handleColumsSort(filters);
        }
      }}
      handleRowClick={(row: any) => {
        // Setting up ticket details to redux state and navigation
        dispatch({
          type: "DOWN_TIME_TICKET",
          payload: row
        });
        navigate(`list-details/${row._id}`);
      }}
    />
  );
};

export default NpiDowmtimeTickets;
