import { ThemeProvider } from '@mui/material/styles'
import { FC, createContext, useEffect, useState } from 'react'
import { StylesProvider } from '@mui/styles'
import { useSelector } from 'react-redux'
import { themeCreator } from './base'

export const ThemeContext = createContext<(themeName: string) => void>(
	(): void => undefined
)

const ThemeProviderWrapper: FC<{ children: any }> = ({ children }) => {
	const usersList = useSelector((state: any) => state.usersList)
	const userPreference = usersList?.currentUser?.settings?.preferred_theme

	const currThemeName =
		userPreference === 'Dark' ? 'PureDarkTheme' : 'PureDarkTheme'
	const [themeName, _setThemeName] = useState(currThemeName)
	const theme = themeCreator(themeName)
	const setThemeName = (themeName: string): void => {
		localStorage.setItem('appTheme', themeName)
		localStorage.setItem(
			'appMode',
			themeName === 'PureDarkTheme' ? 'Dark' : 'Dark'
		)
		_setThemeName(themeName)
	}

	useEffect(() => {
		setThemeName(currThemeName)
	}, [currThemeName])

	return (
		<StylesProvider injectFirst>
			<ThemeContext.Provider value={setThemeName}>
				<ThemeProvider
					theme={{
						...theme,
						applyStyles: () => {
							return
						}
					}}
				>
					{children}
				</ThemeProvider>
			</ThemeContext.Provider>
		</StylesProvider>
	)
}

export default ThemeProviderWrapper
