import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PostAddIcon from '@mui/icons-material/PostAdd'
import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	TextField,
	Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import dayjs from 'dayjs'
import JsFileDownloader from 'js-file-downloader'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { env } from '../../../../env'
import SignalRConnection from '../../../../Services/SignalRConnection'
import { loadSnackbar } from '../../../../Utils/util'
import RichTextEditor from '../../../maintainence/ticket-details/CommentSection/RichTextEditor'
import ImageViewer from '../../../maintainence/ticket-details/mediaSection/ImageViewer'
import CommentDialog from './Components/CommentDialog'
import CommentList from './Components/CommentList'
import ConfirmAlertDialog from './Components/ConfirmAlertDialog'
import CreateUpdateTicket from './Components/CreateUpdateTicket'
import { exportTicketPDF } from './Components/ExportTicketPDF'
import Logility from './Components/Logility'
import PopOverItems, { popOverItem } from './Components/PopOverItems'
import {
	csvExtentions,
	dateTimeFormatter,
	docExtentions,
	getDatesDifference,
	getDiasableStateOfBeverageTicketStatus,
	getDiasableStateOfTicketStatus,
	getTicketBeverageTypeOptions,
	getTicketOptions,
	getTicketStatusOptions,
	getTimeDifference,
	imageExtensions,
	pdfExtensions,
	userHasOnlyFgPlannerRole,
	xlExtentions
} from './Helpers'
import { ReactComponent as CloseIcon } from './Icons/close-icon.svg'
import { ReactComponent as CsvIcon } from './Icons/csv-icon.svg'
import { ReactComponent as DownIconBlack } from './Icons/down-icon-black.svg'
import { ReactComponent as DownIcon } from './Icons/down-icon.svg'
import { ReactComponent as ExcelIcon } from './Icons/excel-iocn.svg'
import { ReactComponent as HoriMenu } from './Icons/more-menu-horizontal.svg'
import { ReactComponent as PdfIcon } from './Icons/pdf-icon.svg'
import { ReactComponent as PriorityIconBlack } from './Icons/priority-icon-black.svg'
import { ReactComponent as PriorityIconWhite } from './Icons/priority-icon-white.svg'
import {
	deleteDownTimeTicket,
	getChatComments,
	getDowntimeticketDetails,
	updateChatComment,
	updateDownTimeTicket,
	updateDownTimeTicketStatus
} from './Services/SupplyChainTikcetService'
import { ticketDetailsStyles } from './TicketDetailsStyles'

const TicketDetailsSupplyChain = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const classes: any = ticketDetailsStyles()
	const usersList = useSelector((state: any) => state.usersList)
	const userListImages = useSelector((state: any) => state.userListImages)
	const plantsList = useSelector((state: any) => state.plantsList)
	const [openAttechment, setOpenAttechment] = useState(false)
	const [showModalImage, setShowModalImage] = React.useState(false)
	const [imageUrl, setImageURL] = React.useState('')
	const [chatroomResponse, setChatroomResponse] = useState<any>({})
	const currentUser = usersList.currentUser
	const [commentDataForAction, setCommentDataForAction] = useState<any>({
		item: {},
		index: -1
	})
	const [addNewComment, setAddNewComment] = useState(false)
	const [anchorElDotPopUp, setAnchorElDotPopUp] =
		React.useState<HTMLButtonElement | null>(null)
	const [anchorElCommentDotPopUp, setAnchorElCommentDotPopUp] =
		React.useState<HTMLButtonElement | null>(null)
	const [
		anchorElAttachmentDownloadDotPopUp,
		setAnchorElAttachmentDownloadDotPopUp
	] = React.useState<HTMLButtonElement | null>(null)
	let openAnchorElDotPopUp = Boolean(anchorElDotPopUp)
	const openAnchorElCommentDotPopUp = Boolean(anchorElCommentDotPopUp)
	const openAnchorAttachmentDownloadDotPopUp = Boolean(
		anchorElAttachmentDownloadDotPopUp
	)
	const [attachmentData, setAttachmentData] = useState<any>(null)
	const [ticketDeleteDialog, setTicketDeleteDialog] = useState(false)
	const [ticketDeleteConfirmation, setTicketDeleteConfirmation] =
		useState(false)
	const [editDialogOpen, setEditDialogOpen] = useState(false)
	const [commentDelete, setCommentDelete] = useState(false)
	const [commentEdit, setCommentEdit] = useState(false)
	const [ticketDeleted, setTicketDeleted] = useState(false)
	const [loader, setLoader] = useState({ chatroomApiLoader: false })
	const [plantTimeZone, setPlantTimeZone] = useState<string>('')
	const userpermissionsList = useSelector(
		(state: any) => state.userPermissionsList
	)
	const [ticketDetails, setTicketDetails]: any = useState({})
	const [isLoading, setIsLoading]: any = useState(true)
	const { ticketId } = useParams()

	const [statusChangeObject, setStatusChangeObject] = useState<{
		commentDialog: boolean
		logilityDilaog: boolean
		ticketStatus: string | null
	}>({
		commentDialog: false,
		logilityDilaog: false,
		ticketStatus: null
	})
	/**
	 * Handle function section
	 * Here we handing the page actions (popups and dialogue actions)
	 */
	const handleClickEditTicket = () => {
		setEditDialogOpen(true)
	}
	const getTicketDetails = async () => {
		try {
			if (ticketId) {
				const tkDetails = await getDowntimeticketDetails(ticketId)
				if (tkDetails && tkDetails?.data?.data) {
					setTicketDetails(tkDetails?.data?.data)
				} else {
					setTicketDetails({})
				}
			}
		} catch (error) {
			setTicketDeleted(true)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getTicketDetails()
	}, [ticketId])

	const handleCloseEditTicket = () => {
		setEditDialogOpen(false)
	}
	const handleCloseTicketDeleteDialog = () => {
		setTicketDeleteDialog(false)
	}
	const handleClickTicketDeleteDialog = () => {
		setTicketDeleteDialog(true)
	}
	const handleCloseTicketDeleteConfirmation = () => {
		setTicketDeleteConfirmation(false)
	}
	const handleClickTicketDeleteConfirmation = () => {
		setTicketDeleteConfirmation(true)
	}
	const handleCloseAttechment = () => {
		setOpenAttechment(false)
	}

	const handleClickOpenAttechment = () => {
		setOpenAttechment(true)
	}

	const handleClickOpenImage = (url: string) => {
		setShowModalImage(true)
		setImageURL(url)
	}
	const handleClickDotPupUp = (e: any) => {
		setAnchorElDotPopUp(e.currentTarget)
	}
	const handleCloseDotPopUp = () => {
		setAnchorElDotPopUp(null)
	}

	const handleCloseCommentDotPopUp = () => {
		setAnchorElCommentDotPopUp(null)
	}
	/**
	 * _handleCloseAttachmentDownloadDotPopUp function definition section
	 * This function helps us to removing/cleaning the stored target position value from the state variable
	 */
	const _handleCloseAttachmentDownloadDotPopUp = () => {
		setAnchorElAttachmentDownloadDotPopUp(null)
	}
	/**
	 * _handleAttachmentFileDownloader function definition section
	 * This function helps us to download the attachment
	 */
	const _handleAttachmentFileDownloader = () => {
		const attach: any = attachmentData
		const fileName =
			attach?.data?.url?.length > 0
				? attach?.data?.url
				: attach?.data?.src?.length > 0
					? attach?.data?.src
					: 'file'
		const lastIndex =
			fileName.lastIndexOf('_') > fileName.lastIndexOf('/')
				? fileName.lastIndexOf('_')
				: fileName.lastIndexOf('/')
		const getFileName =
			fileName.length > 0 && fileName !== 'file'
				? fileName.substring(lastIndex + 1)
				: 'file'
		const decodeFileName =
			getFileName !== '' ? decodeURIComponent(getFileName) : 'file'
		new JsFileDownloader({
			url:
				attach?.data?.url?.length > 0
					? attach?.data?.url
					: attach?.data?.src?.length > 0
						? attach?.data?.src
						: '',
			filename: decodeFileName
		})
	}

	const handleExportToPDF = async () => {
		try {
			await exportTicketPDF()
		} catch (error) {
			dispatch(loadSnackbar())
		}
	}

	const popOverItemAction = (item: popOverItem) => {
		switch (item.key) {
			case 'edit_ticket':
				handleClickEditTicket()
				break
			case 'export_ticket':
				handleExportToPDF()
				break
			case 'delete_ticket':
				handleClickTicketDeleteConfirmation()
				break
			case 'download_attachment':
				_handleAttachmentFileDownloader()
				break
			default:
		}
		handleCloseDotPopUp()
	}

	const handleChangeStatus = (_event: any, newValue: any) => {
		let commentDialog = false
		let logilityDialog = false
		if (
			newValue?.name === 'Master Planner Confirmed' ||
			newValue?.name === 'Finished Goods Planner Confirmed' ||
			newValue?.name === 'Brand Planner Confirmed'
		) {
			logilityDialog = true
		} else {
			commentDialog = true
		}

		setStatusChangeObject({
			commentDialog: commentDialog,
			logilityDilaog: logilityDialog,
			ticketStatus: newValue?.name === 'Rejected' ? 'Reject' : newValue?.name
		})
	}

	const _updateTicketStatus = async (logilityObject?: any): Promise<any> => {
		try {
			const payload: any = {
				_id: ticketDetails?._id,
				dt_ticket_status: statusChangeObject.ticketStatus,
				plant_id: ticketDetails?.plant_id?._id,
				modified_by: currentUser?._id
			}
			if (logilityObject) {
				payload['logility'] = logilityObject
			}

			if (
				userHasOnlyFgPlannerRole(usersList?.currentUserPermissions || []) &&
				!ticketDetails?.master_planner?.master_planner_confirmed
			) {
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						message: 'Master Planner not confirmed!',
						type: 'error',
						open: true
					}
				})
				return false
			}

			const response = await updateDownTimeTicketStatus(payload)
			if (response) {
				getTicketDetails()
				if (response && response?.data?.error_description) {
					dispatch({
						type: 'LOAD_SNACKBAR',
						payload: {
							message: response?.data?.error_description,
							type: 'error',
							open: true
						}
					})
					return
				} else {
					dispatch({
						type: 'LOAD_SNACKBAR',
						payload: {
							message: response?.data?.message,
							type: 'success',
							open: true
						}
					})
				}
				return true
			}
		} catch (error: any) {
			dispatch({
				type: 'LOAD_SNACKBAR',
				payload: {
					message: 'Error while updating ticket status',
					type: 'error',
					open: true
				}
			})
		}
	}

	/**
	 * _handleCloseAddNewComment function definition section
	 * This function helps us to close popups after the successful/failed comment update/create/delete
	 */
	const _handleCloseAddNewComment = (): void => {
		setAddNewComment(false)
		setCommentEdit(false)
		setAnchorElCommentDotPopUp(null)
	}

	/**
	 * _handleClickCommentDotPupUp function definition section
	 * Here we capturing the individual comment and their index
	 * @param e
	 * @param item
	 * @param index
	 */
	const _handleClickCommentDotPupUp = (e: any, item: any, index: any): void => {
		setCommentDataForAction({
			...commentDataForAction,
			item: item,
			index: index
		})
		setAnchorElCommentDotPopUp(e.currentTarget)
	}
	/**
	 * _handleClickAttachmentDownloadDotPupUp function definition section
	 * This function helps us to set a popup target position value to state variable
	 * @param e
	 */
	const _handleClickAttachmentDownloadDotPupUp = (e: any): void => {
		setAnchorElAttachmentDownloadDotPopUp(e.currentTarget)
	}
	/**
	 * _getUserInfo function definition section
	 * This function help us to get commenter name, role and profile image using email
	 * @param email
	 */
	const _getUserInfo = (email: string): any => {
		const userObj = usersList?.users.find((user: any) => {
			return user.mail === email
		})

		const userRoles: any = []

		if (userObj?.user_arch_group?.length > 0) {
			userObj?.user_arch_group
				// Filter out Plant and Asset Manager role
				.filter((role: any) => {
					const plantManagerRoleIds = userpermissionsList?.userPermissions
						.filter(
							(permission: any) =>
								permission.role_name?.split('_')[1] === 'PM' ||
								permission.role_name === 'ARCH_ASSET_MANAGER'
						)
						.map((permission: any) => permission._id)

					return !plantManagerRoleIds.includes(role + '')
				})
				.map((role: any) => {
					const roleDetails = userpermissionsList.userPermissions.find(
						(ele: any) => ele._id === role
					)

					if (
						roleDetails !== null &&
						(roleDetails?.role_name.includes('SCM') ||
							roleDetails?.role_name.includes('ADMIN'))
					) {
						userRoles.push(roleDetails?.role_Permission_List?.permission_name)
					}
					//  else {
					//   userRoles.push(roleDetails?.role_Permission_List?.permission_name);
					// }
				})
		}

		const rolesString = userRoles?.length > 0 ? userRoles.join(', ') : '-'

		const currentUser = userListImages.users?.find((data: any) => {
			return data?._id === userObj?._id
		})
		return {
			display_name: userObj?.display_name,
			role: rolesString,
			profile_image:
				currentUser?.userImage === undefined || currentUser?.userImage === null
					? ''
					: currentUser?.userImage
		}
	}
	/**
	 * _handleCreateUpdateComment function definition section
	 * This function helps us to create and update the comments
	 * @param rawValue
	 */
	const _handleCreateUpdateComment = (rawValue: any) => {
		let statusMessage = ''
		const mentionedUsers = extractMentionedUsers(rawValue) // Extract mentioned users from rawValue
		const mentionedToEmails = getMentionedEmails(mentionedUsers) // Get emails of mentioned users
		const newComment = {
			text: {
				blocks: rawValue.blocks || [],
				entityMap: rawValue.entityMap || {}
			},
			from: commentEdit
				? chatroomResponse?.data?.messages?.[commentDataForAction.index]?.from
				: usersList.currentUser.mail,
			attachments: [],
			device_name: '',
			is_edited: true,
			created_on: new Date().toISOString()
		}
		const payload: any = {
			created_on: chatroomResponse?.data?.created_on,
			last_message_on: new Date().toISOString(),
			last_message_by: usersList.currentUser.mail
		}
		if (commentEdit) {
			const messages = chatroomResponse?.data?.messages
			messages[commentDataForAction.index] = newComment
			payload['messages'] = [...messages]
			statusMessage = 'updated'
		} else {
			payload['messages'] = [
				...(chatroomResponse?.data?.messages || []),
				newComment
			]
			statusMessage = 'added'
		}

		updateChatComment({
			payload,
			_id: ticketDetails?.chatroom_id?._id,
			currentUserId: usersList.currentUser?._id
		})
			.then(() => {
				handleCloseDotPopUp()
				openAnchorElDotPopUp = false
				setAnchorElDotPopUp(null)
				setAnchorElCommentDotPopUp(null)
				getTicketMessages()
				// Implement SignalR notification for ticket creation
				if (mentionedToEmails.length > 0) {
					const alreadyMentioned = [String]
					const mentions = []
					for (let i = 0; i < mentionedToEmails.length; i++) {
						const currEmail = mentionedToEmails[i]
						if (alreadyMentioned.includes(currEmail)) {
							continue
						}
						alreadyMentioned.push(mentionedToEmails[i])
						mentions.push({
							username: mentionedUsers[i],
							email: mentionedToEmails[i]
						})
					}
					sendMentionNotification(mentions, rawValue)
				}
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						message: `Comment ${statusMessage} successfully`,
						type: 'success',
						open: true
					}
				})
			})
			.catch(() => dispatch(loadSnackbar()))
		_handleCloseAddNewComment()
	}

	// extract mentioned users from rawValue
	const extractMentionedUsers = (rawValue: any) => {
		return Object.values(rawValue?.entityMap)
			.filter((ele: any) => ele?.type === 'MENTION')
			.map((ele: any) => ele?.data?.value)
	}

	// get emails of mentioned users
	const getMentionedEmails = (mentionedUsers: string[]) => {
		return mentionedUsers
			.map((username: string) => {
				return usersList.users.find(
					(user: any) => user.user_ad_name === username
				)?.mail
			})
			.filter(ele => ele !== undefined)
	}

	const sendMentionNotification = (
		mentions: Array<{ username: string; email: string }>,
		rawValue: any
	) => {
		const messageWithLinks = rawValue.blocks
			.map((block: any) => block.text)
			.join(' ')
		let emailMessage = messageWithLinks // Initialize emailMessage with the original message

		// Check if there are mentions
		if (mentions.length > 0) {
			// Replace mentions with mailto hyperlinks in the text
			mentions.forEach(mention => {
				const mentionRegex = new RegExp(`@${mention.username}`, 'g')
				emailMessage = emailMessage.replace(
					mentionRegex,
					`<a href="mailto:${mention.email}">${mention.username}</a>`
				)
			})
		}

		axios.post(env.LogicApp_CommentMention, {
			mentionFrom: usersList.currentUser.user_ad_name,
			mentions: mentions,
			ticketId: ticketDetails._id,
			linkToTicket: window.location.href,
			comment: emailMessage,
			teamsComment: messageWithLinks,
			attachments: []
		})
	}

	/**
	 * _handleCommentDelete function definition section
	 * This function helps us to delete the comment based on index
	 */
	const _handleCommentDelete = () => {
		if (commentDataForAction.index > -1) {
			chatroomResponse?.data?.messages?.splice(commentDataForAction.index, 1)
		}
		const payload = {
			created_on: chatroomResponse?.data?.created_on,
			last_message_on: new Date().toISOString(),
			last_message_by: usersList.currentUser.mail,
			messages: chatroomResponse?.data?.messages
		}
		updateChatComment({
			payload,
			_id: ticketDetails?.chatroom_id?._id
		})
			.then(() => {
				handleCloseDotPopUp()
				openAnchorElDotPopUp = false
				setAnchorElDotPopUp(null)
				setAnchorElCommentDotPopUp(null)
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						message: 'Comment removed successfully',
						type: 'success',
						open: true
					}
				})
			})
			.catch(() => dispatch(loadSnackbar()))
	}

	/**
	 * _handleUpdateTicket function definition section
	 * This function helps us to create and edit the comments
	 * @param payload
	 */
	const _handleUpdateTicket = async (payload: any): Promise<void> => {
		try {
			const updateTicketPayload = {
				...payload,
				dt_ticket_category: ticketDetails?.dt_ticket_category,
				currentUserId: usersList.currentUser?._id
			}
			const response = await updateDownTimeTicket(updateTicketPayload)
			if (response) {
				setIsLoading(true)
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						message: 'Ticket Updated!',
						type: 'success',
						open: true
					}
				})
				// Get API...Calling
				getTicketDetails()
			}
			handleCloseEditTicket()
		} catch (error) {
			dispatch(loadSnackbar('Error while updating ticket'))
		} finally {
			setAnchorElDotPopUp(null)
		}
	}
	/**
	 * getTicketMessages function definition section
	 * This function helps us to get and set chat comments and set ticket plant time zone.
	 */
	const getTicketMessages = () => {
		if (Object.keys(ticketDetails)?.length > 0) {
			const pantInfo: any = plantsList?.plants?.find((item: any) => {
				return item?._id === ticketDetails?.plant_id?._id
			})
			if (pantInfo) {
				setPlantTimeZone(pantInfo?.plant_timezone)
			}
			setLoader({ ...loader, chatroomApiLoader: true })
			const payload = {
				_id: ticketDetails?.chatroom_id?._id
			}
			getChatComments(payload)
				.then((res: any) => {
					setLoader({ ...loader, chatroomApiLoader: false })
					res?.data?.messages?.sort(function (a: any, b: any) {
						return a?.created_on > b?.created_on
							? -1
							: a?.created_on < b?.created_on
								? 1
								: 0
					})
					setChatroomResponse(res)
				})
				.catch(() => {
					setLoader({ ...loader, chatroomApiLoader: false })
				})
				.finally(() => {
					setLoader({ ...loader, chatroomApiLoader: false })
				})
		}
	}
	/**
	 * _handleViewImageInCommentSection function definition section
	 * This function helps us to view the image from the comment list section
	 * @param item
	 */
	const _handleViewImageInCommentSection = (item: any) => {
		const url = item?.entityMap[0]?.data?.src || ''
		if (url.trim().length > 0) {
			const urlSplit = url?.split('.')
			const attachmentType = urlSplit[urlSplit?.length - 1]
			if (imageExtensions.includes(attachmentType)) {
				handleClickOpenImage(url)
			}
		}
	}
	/**
	 * useEffect section
	 * Calling getTicketMessages function to get selected ticket information
	 */

	const connectToSignalR = () => {
		const connection = SignalRConnection.connectToSignalR()

		//This method is called to create the connection
		//to SignalR so the client can receive messages
		connection.start()
		// .then(() => {})
		// .catch(() => {});

		connection.on('downtime', function (msg: any) {
			if (window.location.href?.includes(msg?.ticket_id)) {
				getTicketDetails()
			}
		})
	}

	useEffect(() => {
		connectToSignalR()
	}, [])

	useEffect(() => {
		getTicketMessages()
	}, [ticketDetails])

	const handleDeleteTicket = async (): Promise<void> => {
		try {
			const deleteResponse = await deleteDownTimeTicket(ticketId)
			if (deleteResponse) {
				handleCloseTicketDeleteConfirmation()
				handleClickTicketDeleteDialog()
			}
		} catch (error) {
			dispatch({
				type: 'LOAD_SNACKBAR',
				payload: {
					message: 'Error while delete ticket!',
					type: 'error',
					open: true
				}
			})
		}
	}

	/**
	 *  Message attachment with proper icon and action.
	 * @param url
	 * @returns
	 */
	const eachAttachmentIconAndAction = (data: any) => {
		let url = ''
		if (data?.url?.length > 0) {
			url = data?.url
		} else if (data?.src?.length > 0) {
			url = data?.src
		}
		if (url?.length > 0) {
			const urlSplit = url?.split('.')
			const attachmentType = urlSplit[urlSplit?.length - 1]
			if (xlExtentions.includes(attachmentType)) {
				return (
					<a href={url} target='_blank' rel='noreferrer'>
						<ExcelIcon style={{ height: 60, width: 80 }} />
					</a>
				)
			} else if (pdfExtensions.includes(attachmentType)) {
				return (
					<a href={url} target='_blank' rel='noreferrer'>
						<PdfIcon style={{ height: 60, width: 80 }} />
					</a>
				)
			} else if (docExtentions.includes(attachmentType)) {
				return (
					<a href={url} target='_blank' rel='noreferrer'>
						<PostAddIcon style={{ height: 60, width: 80 }} />
					</a>
				)
			} else if (imageExtensions.includes(attachmentType)) {
				return (
					<div
						onClick={() => {
							handleClickOpenImage(url)
						}}
						style={{ cursor: 'pointer' }}
					>
						<img src={url} className={classes.dialogImage} alt=''></img>
					</div>
				)
			} else if (csvExtentions.includes(attachmentType)) {
				return (
					<a href={url} target='_blank' rel='noreferrer'>
						<CsvIcon style={{ height: 60, width: 80 }} />
					</a>
				)
			}
		}
	}

	/**
	 * Each message attachments
	 * @param item
	 * @returns
	 */
	const chatroomAttachments = (
		item: any,
		entity: any,
		entityIndex: number
	): any => {
		return (
			<Box className={classes.attachmentContainer} key={entityIndex}>
				<Box className={classes.attachmentDesc}>
					<Typography variant='body1'>
						{_getUserInfo(item?.from)?.display_name}
					</Typography>
					<Typography variant='body2'>
						{_getUserInfo(item?.from)?.role}
					</Typography>
					<Typography variant='body2' component='span'>
						{`${moment(item.created_on)
							.tz('America/Los_Angeles')
							.format('MM/DD/YYYY, hh:mm A')} PST`}
					</Typography>
				</Box>
				<Box className={classes.attachmentBox}>
					{/* need to work */}
					<HoriMenu
						onClick={(e: any) => {
							_handleClickAttachmentDownloadDotPupUp(e)
							setAttachmentData(entity)
						}}
						className={classes.hMenuIcon}
					/>
					<PopOverItems
						open={openAnchorAttachmentDownloadDotPopUp}
						anchorEl={anchorElAttachmentDownloadDotPopUp}
						popOverItmes={[
							{
								label: 'Download',
								key: 'download_attachment'
							}
						]}
						popOverItemAction={popOverItemAction}
						closePopUp={_handleCloseAttachmentDownloadDotPopUp}
					/>
					{eachAttachmentIconAndAction(entity?.data)}
				</Box>
			</Box>
		)
	}

	const updateStatusAndComment = async (rawValue: any): Promise<void> => {
		try {
			_handleCreateUpdateComment(rawValue)
		} catch (error) {
			dispatch(loadSnackbar())
		}
	}

  const getOverAllDowntimeTicketStatus = (ticket: any, caller: string) => {
		const ticketStatus = ticket?.dt_ticket_status

		const masterPlannerConfirmed: boolean =
			ticket?.master_planner?.master_planner_confirmed
		const fgPlannerConfirmed: boolean =
			ticket?.finished_goods_planner?.finished_goods_planner_confirmed
		const brandPlannerConfirmed: boolean =
			ticket?.brand_planner?.brand_planner_confirmed

		let message = ''
		let styleCSS = ''
		
		if (ticketStatus === 'Reject') {
			message = 'Declined'
			styleCSS = classes.ticketReject
		} else if (!fgPlannerConfirmed && masterPlannerConfirmed) {
			message = 'Pending Approval - FG Planner'
			styleCSS = classes.ticketPendingApproval
		} else if (
			ticketStatus === 'Finished Goods Planner Confirmed' ||
			ticketStatus === 'Brand Planner Confirmed' ||
			ticketStatus === 'Closed'
		) {
			message = 'Approved'
			styleCSS = classes.ticketApproved
		} else if (
			(['Open', 'In Progress', 'TBD']?.includes(ticketStatus) &&
				!masterPlannerConfirmed) ||
			!brandPlannerConfirmed
		) {
			message = 'Pending Approval'
			styleCSS = classes.ticketPendingApproval
		} else {
			message = ticketStatus
			styleCSS = classes.ticketPendingApproval
		}

		if (caller === 'message') return message
		if (caller === 'styleCSS') return styleCSS
	}

	return (
		<>
			{isLoading ? (
				<CircularProgress style={{ margin: '20% 45%' }} />
			) : (
				<div id='ticket-details-container'>
					<Box className={classes.criticalControlSec}>
						<Grid
							container
							direction='row'
							justifyContent='between'
							alignItems='left'
							spacing={2}
							rowSpacing={4}
							style={{
								padding: '40px 20px 20px'
							}}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Box
									sx={{
										display: 'flex',
										width: '50%',
										justifyContent: 'space-between'
									}}
								>
									<Button
										style={{ width: '65px', backgroundColor: '#8467ef' }}
										variant='outlined'
										onClick={() => {
											if (location?.state?.from === 'alerts') {
												navigate(`/usecase/downtime-request/alerts`, {
													replace: true
												})
											} else {
												navigate(`/usecase/downtime-request`, {
													replace: true
												})
											}
										}}
										startIcon={<ArrowBackIcon style={{ margin: '0px' }} />}
									/>

									<Box>
										<Typography
											variant='body1'
											textAlign='center'
											sx={{ fontSize: '12px' }}
										>
											{ticketDetails?.plant_name} Downtime Requests
										</Typography>

										<Typography
											variant='h2'
											textAlign='center'
											sx={{ fontSize: '1.2rem' }}
										>
											Ticket Details
										</Typography>
									</Box>
									{/* <Box className={classes.fliterMenus}>
                  <Button variant="outlined">Bookmark</Button>
                </Box> */}
								</Box>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Divider />
							</Grid>

							{ticketDeleted ? (
								<Grid item xs={12} sm={12} md={12} mr={12}>
									<Typography
										variant='h2'
										textAlign='center'
										justifyContent='center'
									>
										Ticket Deleted!
									</Typography>
								</Grid>
							) : (
								<>
									<Grid item xs={12} sm={12} md={12}>
										<Box justifyContent='flex-end' gap='24px' display='flex'>
											<Button
												variant='outlined'
												className={`${classes.priorityBtn}
               ${
									ticketDetails?.dt_ticket_priority === 'High'
										? classes.HighPriority
										: ticketDetails?.dt_ticket_priority === 'Low'
											? classes.LowPriority
											: classes.MediumPriority
								}`}
												endIcon={
													ticketDetails?.dt_ticket_priority === 'High' ? (
														<PriorityIconWhite />
													) : (
														<PriorityIconBlack />
													)
												}
											>
												{ticketDetails?.dt_ticket_priority} Priority
											</Button>

											{/* Ticket status dropdown */}
											<Autocomplete
												className={`${classes.autoDropdown} ${
													ticketDetails?.dt_ticket_status === 'Open'
														? classes.ticketStausOpen
														: ticketDetails?.dt_ticket_status === 'Closed'
															? classes.ticketStatusClosed
															: ticketDetails?.dt_ticket_status ===
																  'In Progress'
																? classes.ticketStausInProgress
																: ticketDetails?.dt_ticket_status === 'TBD'
																	? classes.ticketStausTBD
																	: ticketDetails?.dt_ticket_status ===
																		  'Confirmed'
																		? classes.ticketStausConfirmed
																		: classes.ticketStausPlannerConfirmed
												}`}
												options={
													ticketDetails?.dt_ticket_type === 'Water'
														? getTicketStatusOptions(
																usersList?.currentUserPermissions || [],
																ticketDetails,
																currentUser
															)
														: getTicketBeverageTypeOptions(
																usersList?.currentUserPermissions || [],
																ticketDetails,
																currentUser
															)
												}
												value={{
													name:
														ticketDetails?.dt_ticket_status ===
														'Master Planner Confirmed'
															? 'MP Confirmed'
															: ticketDetails?.dt_ticket_status ===
																  'Finished Goods Planner Confirmed'
																? 'FG Confirmed'
																: ticketDetails?.dt_ticket_status ===
																	  'Brand Planner Confirmed'
																	? 'BP Confirmed'
																	: ticketDetails?.dt_ticket_status === 'Reject'
																		? 'Rejected'
																		: ticketDetails?.dt_ticket_status
												}}
												getOptionLabel={(option: any) => option.name} // Use option.label
												onChange={(_event: any, newValue: any) => {
													handleChangeStatus(_event, newValue) // Update the selectedPlant state
												}}
												popupIcon={
													ticketDetails?.dt_ticket_status === 'In Progress' ||
													ticketDetails?.dt_ticket_status ===
														'Master Planner Confirmed' ||
													ticketDetails?.dt_ticket_status ===
														'Finished Goods Planner Confirmed' ? (
														<DownIconBlack />
													) : (
														<DownIcon />
													)
												}
												renderInput={(params: any) => (
													<TextField {...params} placeholder='Ticket Status' />
												)}
												disableClearable
												readOnly={
													ticketDetails?.dt_ticket_type === 'Water'
														? getDiasableStateOfTicketStatus(
																usersList?.currentUserPermissions || [],
																ticketDetails,
																currentUser
															)
														: getDiasableStateOfBeverageTicketStatus(
																usersList?.currentUserPermissions || [],
																ticketDetails,
																currentUser
															)
												}
											/>
											{/* {(usersList?.currentUser?.display_name ===
                        ticketDetails?.created_by?.user_ad_name ||
                        usersList.currentUserPermissions.some((ele: any) => {
                          return ele.includes("ARCH_ADMIN") || ele.includes("ARCH_SCM_ADMIN");
                        })) && ( */}
											<Box className={classes.MoreVertiIcon}>
												<IconButton
													aria-controls={
														anchorElDotPopUp ? 'long-menu' : undefined
													}
													aria-expanded={anchorElDotPopUp ? 'true' : undefined}
													aria-haspopup='true'
													onClick={handleClickDotPupUp}
													aria-label='Open to show more'
													title='Open to show more'
													disableRipple
												>
													<MoreVertIcon />
												</IconButton>
											</Box>
											{/* )} */}
										</Box>
									</Grid>

									{/* Edit Tikcet, Export Ticket and Delete ticket Poover */}
									<PopOverItems
										open={openAnchorElDotPopUp}
										anchorEl={anchorElDotPopUp}
										popOverItmes={getTicketOptions(
											usersList?.currentUserPermissions || [],
											ticketDetails
										)}
										popOverItemAction={popOverItemAction}
										closePopUp={handleCloseDotPopUp}
									/>

									<Grid item xs={12} sm={12} md={12}>
										<Box className={classes.ticketDetail}>
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>Ticket ID</Typography>
												<Typography variant='body2'>
													{ticketDetails.dt_ticket_alias}
												</Typography>
											</Box>
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>
													Ticket Created By
												</Typography>
												<Typography variant='body2'>
													{ticketDetails?.created_by?.user_ad_name}
												</Typography>
											</Box>
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>
													Ticket Creation date
												</Typography>
												<Typography variant='body2'>
													{`${moment(ticketDetails.created_on)
														.tz('America/Los_Angeles')
														.format('MM/DD/YYYY, hh:mm A')} PST`}

													{/* {`${dateTimeFormatter(
                        ticketDetails?.created_on,
                        "MM/DD/YYYY"
                      )} (${dateTimeFormatter(
                        ticketDetails?.created_on,
                        "hh:mm A"
                      )} PST)`} */}

													{/* Time is missed, because for not storing timestamp */}
												</Typography>
											</Box>
											{(ticketDetails?.dt_ticket_category === 'OH' ||
												ticketDetails?.dt_ticket_category === 'DV') && (
												<Box className={classes.ticketDetailBox}>
													<Typography variant='body1'>Vendor</Typography>
													<Typography variant='body2'>
														{ticketDetails?.dt_vendor}
													</Typography>
												</Box>
											)}
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>
													Ticket Open Duration
												</Typography>
												<Typography variant='body2'>
													{ticketDetails.dt_ticket_status === 'Closed' ||
													ticketDetails.dt_ticket_status === 'Reject'
														? getTimeDifference(
																ticketDetails?.created_on,
																ticketDetails?.dt_ticket_open_duration_timestamp
															)
														: getTimeDifference(
																ticketDetails?.created_on,
																new Date().toISOString()
															)}
												</Typography>
											</Box>
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>Plant name</Typography>
												<Typography variant='body2'>
													{ticketDetails?.plant_name}
												</Typography>
											</Box>
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>Region</Typography>
												<Typography variant='body2'>
													{ticketDetails?.dt_region}
												</Typography>
											</Box>
											{/* <Box className={classes.ticketDetailBox}>
                  <Typography variant="body1">Vendor</Typography>
                  <Typography variant="body2">-</Typography>
                </Box> */}
											<Box className={classes.ticketDetailBox}>
												<Typography variant='body1'>Line</Typography>
												<Typography variant='body2'>
													{ticketDetails?.dt_line_id}
												</Typography>
											</Box>
										</Box>
									</Grid>

									<Grid item xs={12} sm={12} md={8}>
										<Box className={classes.detailCard}>
											<Box className={classes.detailCardHeader}>
												<Typography
													variant='body1'
													className={classes.detailCardTitle}
												>
													Downtime Details
												</Typography>
												<Button
													variant='outlined'
													// COMMENTING THE STYLES FOR FUTURE USE
													// className={
													// 	ticketDetails?.dt_ticket_status === 'Open'
													// 		? classes.openTicketOpen
													// 		: ticketDetails?.dt_ticket_status === 'Closed'
													// 			? classes.openTicketClosed
													// 			: ticketDetails?.dt_ticket_status ===
													// 				  'In Progress'
													// 				? classes.openTicketInProgress
													// 				: ticketDetails?.dt_ticket_status === 'TBD'
													// 					? classes.openTicketTBD
													// 					: classes.openTicketPlannerConfirmed
													// }
													className={getOverAllDowntimeTicketStatus(
														ticketDetails,
														'styleCSS'
													)}
													style={{ pointerEvents: 'none' }}
												>
													{getOverAllDowntimeTicketStatus(
														ticketDetails,
														'message'
													)}
												</Button>
											</Box>
											<Box className={classes.detailCardBox}>
												<Box className={classes.detailCardContent}>
													<Typography variant='body1'>Downtime Type</Typography>
													<Typography variant='body2'>
														{ticketDetails.dt_ticket_type}
													</Typography>
												</Box>

												<Box className={classes.detailCardContent}>
													<Typography variant='body1'>
														Downtime Start Date
													</Typography>
													<Typography variant='body2'>
														{moment(
															ticketDetails.dt_start_date.split('T')[0]
														).format('MM/DD/YYYY')}
													</Typography>
												</Box>

												<Box className={classes.detailCardContent}>
													<Typography variant='body1'>
														Downtime End Date
													</Typography>
													<Typography variant='body2'>
														{moment(
															ticketDetails.dt_end_date.split('T')[0]
														).format('MM/DD/YYYY')}
													</Typography>
												</Box>

												<Box className={classes.detailCardContent}>
													<Typography variant='body1'>Days</Typography>
													<Typography variant='body2'>
														{getDatesDifference(
															ticketDetails?.dt_start_date,
															ticketDetails?.dt_end_date
														)}
													</Typography>
												</Box>

												<Box className={classes.detailCardContent}>
													<Typography variant='body1'>Hours</Typography>
													<Typography variant='body2'>
														{ticketDetails?.dt_hours}
													</Typography>
												</Box>
											</Box>
										</Box>
										{ticketDetails?.dt_ticket_type === 'Water' && (
											<>
												<Box className={classes.detailCard}>
													<Box className={classes.detailCardHeader}>
														<Typography
															variant='body1'
															className={classes.detailCardTitle}
														>
															Master Planner
														</Typography>
														<Button
															variant='outlined'
															// COMMENTING THE STYLES FOR FUTURE USE
															// className={
															// 	['Open', 'In Progress', 'TBD']?.includes(
															// 		ticketDetails?.master_planner
															// 			?.master_planner_ticket_status
															// 	)
															// 		? classes.openTicketOpen
															// 		: ticketDetails?.master_planner
															// 					?.master_planner_ticket_status ===
															// 					'Closed' ||
															// 			  ticketDetails?.master_planner
															// 					?.master_planner_confirmed
															// 			? classes.openTicketClosed
															// 			: classes.openTicketPlannerConfirmed
															// }
															className={
																ticketDetails?.master_planner
																	?.master_planner_ticket_status === 'Closed' ||
																ticketDetails?.master_planner
																	?.master_planner_confirmed
																	? classes.ticketApproved
																	: ticketDetails?.master_planner
																				?.master_planner_ticket_status ===
																		  'Reject'
																		? classes.ticketReject
																		: classes.ticketPendingApproval
															}
															style={{ pointerEvents: 'none' }}
														>
															{ticketDetails?.master_planner
																?.master_planner_ticket_status === 'Reject'
																? 'Declined'
																: ticketDetails?.master_planner
																			?.master_planner_confirmed
																	? 'Approved'
																	: 'Pending Approval'}
														</Button>
													</Box>
													<Box className={classes.detailCardBox}>
														<Box className={classes.detailCardContent}>
															<Typography variant='body1'>
																Master Planner Name
															</Typography>
															<Typography variant='body2'>
																{
																	ticketDetails?.master_planner
																		?.master_planner_name
																}
															</Typography>
														</Box>

														<Box className={classes.detailCardContent}>
															<Typography variant='body1'>
																Confirmed Duration Days/Hours
															</Typography>
															<Typography variant='body2'>
																{ticketDetails?.master_planner
																	?.master_planner_confirmed
																	? getTimeDifference(
																			ticketDetails?.created_on,
																			ticketDetails?.master_planner
																				?.master_planner_confirmed_timestamp
																		)
																	: '-'}
															</Typography>
														</Box>

														<Box className={classes.detailCardContent}>
															<Typography variant='body1'>
																Confirmed Date Master Planner
															</Typography>
															<Typography variant='body2'>
																{dateTimeFormatter(
																	ticketDetails?.master_planner
																		?.master_planner_confirmed_timestamp,
																	'MM/DD/YYYY'
																)}
															</Typography>
														</Box>
													</Box>
												</Box>

												<Box className={classes.detailCard}>
													<Box className={classes.detailCardHeader}>
														<Typography
															variant='body1'
															className={classes.detailCardTitle}
														>
															FG Planner
														</Typography>
														<Button
															variant='outlined'
															// COMMENTING THE STYLES FOR FUTURE USE
															// className={
															// 	['Open', 'TBD']?.includes(
															// 		ticketDetails?.finished_goods_planner
															// 			?.finished_goods_planner_ticket_status
															// 	)
															// 		? classes.openTicketOpen
															// 		: ticketDetails?.finished_goods_planner
															// 					?.finished_goods_planner_ticket_status ===
															// 					'Closed' ||
															// 			  ticketDetails?.finished_goods_planner
															// 					?.finished_goods_planner_confirmed
															// 			? classes.openTicketClosed
															// 			: classes.openTicketPlannerConfirmed
															// }
															className={
																ticketDetails?.finished_goods_planner
																	?.finished_goods_planner_ticket_status ===
																	'Closed' ||
																ticketDetails?.finished_goods_planner
																	?.finished_goods_planner_confirmed
																	? classes.ticketApproved
																	: ticketDetails?.finished_goods_planner
																				?.finished_goods_planner_ticket_status ===
																		  'Reject'
																		? classes.ticketReject
																		: classes.ticketPendingApproval
															}
															style={{ pointerEvents: 'none' }}
														>
															{ticketDetails?.finished_goods_planner
																?.finished_goods_planner_ticket_status ===
															'Reject'
																? 'Declined'
																: ticketDetails?.finished_goods_planner
																			?.finished_goods_planner_confirmed
																	? 'Approved'
																	: 'Pending Approval'}
														</Button>
													</Box>
													<Box className={classes.detailCardBox}>
														<Box className={classes.detailCardContent}>
															<Typography variant='body1'>
																Finished Goods Planner Name
															</Typography>
															<Typography variant='body2'>
																{
																	ticketDetails?.finished_goods_planner
																		?.finished_goods_planner_name
																}
															</Typography>
														</Box>

														<Box className={classes.detailCardContent}>
															<Typography variant='body1'>
																Confirmed Duration Days/Hours
															</Typography>
															<Typography variant='body2'>
																{ticketDetails?.finished_goods_planner
																	?.finished_goods_planner_confirmed
																	? getTimeDifference(
																			ticketDetails?.master_planner
																				?.master_planner_confirmed_timestamp,
																			ticketDetails?.finished_goods_planner
																				?.finished_goods_planner_confirmed_timestamp
																		)
																	: '-'}
															</Typography>
														</Box>

														<Box className={classes.detailCardContent}>
															<Typography variant='body1'>
																Confirmed Date FG Planner
															</Typography>
															<Typography variant='body2'>
																{dateTimeFormatter(
																	ticketDetails?.finished_goods_planner
																		?.finished_goods_planner_confirmed_timestamp,
																	'MM/DD/YYYY'
																)}
															</Typography>
														</Box>
													</Box>
												</Box>
											</>
										)}
										{ticketDetails?.dt_ticket_type === 'Beverage' && (
											<Box className={classes.detailCard}>
												<Box className={classes.detailCardHeader}>
													<Typography
														variant='body1'
														className={classes.detailCardTitle}
													>
														Brand Planner
													</Typography>
													<Button
														variant='outlined'
														// COMMENTING THE STYLES FOR FUTURE USE
														// className={
														// 	['Open', 'In Progress', 'TBD']?.includes(
														// 		ticketDetails?.brand_planner
														// 			?.brand_planner_ticket_status
														// 	)
														// 		? classes.openTicketOpen
														// 		: ticketDetails?.brand_planner
														// 					?.brand_planner_ticket_status ===
														// 					'Closed' ||
														// 			  ticketDetails?.brand_planner
														// 					?.brand_planner_confirmed
														// 			? classes.openTicketClosed
														// 			: classes.openTicketPlannerConfirmed
														// }
														className={
															ticketDetails?.brand_planner
																?.brand_planner_ticket_status === 'Closed' ||
															ticketDetails?.brand_planner
																?.brand_planner_confirmed
																? classes.ticketApproved
																: ticketDetails?.brand_planner
																			?.brand_planner_ticket_status === 'Reject'
																	? classes.ticketReject
																	: classes.ticketPendingApproval
														}
														style={{ pointerEvents: 'none' }}
													>
														{ticketDetails?.brand_planner
															?.brand_planner_ticket_status === 'Reject'
															? 'Declined'
															: ticketDetails?.brand_planner
																		?.brand_planner_confirmed
																? 'Approved'
																: 'Pending Approval'}
													</Button>
												</Box>
												<Box className={classes.detailCardBox}>
													<Box className={classes.detailCardContent}>
														<Typography variant='body1'>
															Brand Planner Name
														</Typography>
														<Typography variant='body2'>
															{ticketDetails?.brand_planner?.brand_planner_name}
														</Typography>
													</Box>

													<Box className={classes.detailCardContent}>
														<Typography variant='body1'>
															Confirmed Duration Days/Hours
														</Typography>
														<Typography variant='body2'>
															{ticketDetails?.brand_planner
																?.brand_planner_confirmed
																? getTimeDifference(
																		ticketDetails?.created_on,
																		ticketDetails?.brand_planner
																			?.brand_planner_confirmed_timestamp
																	)
																: '-'}
														</Typography>
													</Box>

													<Box className={classes.detailCardContent}>
														<Typography variant='body1'>
															Confirmed Date Brand Planner
														</Typography>
														<Typography variant='body2'>
															{dateTimeFormatter(
																ticketDetails?.brand_planner
																	?.brand_planner_confirmed_timestamp,
																'MM/DD/YYYY'
															)}
														</Typography>
													</Box>
												</Box>
											</Box>
										)}
									</Grid>

									<Grid
										item
										xs={12}
										sm={12}
										md={4}
										className={classes.createBtn}
									>
										<Box className={classes.detailCard}>
											<Box className={classes.commentHead}>
												<Typography variant='body1'>Comments</Typography>
												<Button
													variant='outlined'
													endIcon={<AddCircleOutlineIcon />}
													className={classes.addNewBtn}
													onClick={() => setAddNewComment(true)}
												>
													Add New
												</Button>
												<Button
													variant='outlined'
													className={classes.Attachments}
													endIcon={<AttachFileIcon />}
													onClick={handleClickOpenAttechment}
												>
													Attachments
												</Button>
											</Box>
											<Box className={classes.CommentsWrapper}>
												{!loader.chatroomApiLoader &&
												!userListImages.loading ? (
													<>
														{chatroomResponse?.data?.messages?.length === 0 ? (
															<Grid
																container
																alignItems='center'
																justifyContent='center'
															>
																<Typography variant='body1'>No data</Typography>
															</Grid>
														) : (
															<>
																{' '}
																{chatroomResponse?.data?.messages?.map(
																	(item: any, index: any) => (
																		<>
																			<CommentList
																				item={item}
																				index={index}
																				anchorElCommentDotPopUp={
																					anchorElCommentDotPopUp
																				}
																				_handleClickCommentDotPupUp={
																					_handleClickCommentDotPupUp
																				}
																				openAnchorElCommentDotPopUp={
																					openAnchorElCommentDotPopUp
																				}
																				setCommentEdit={setCommentEdit}
																				setCommentDelete={setCommentDelete}
																				_getUserInfo={_getUserInfo}
																				handleCloseCommentDotPopUp={
																					handleCloseCommentDotPopUp
																				}
																				_handleViewImageInCommentSection={
																					_handleViewImageInCommentSection
																				}
																				plantTimeZone={plantTimeZone}
																				userPermission={usersList}
																			/>
																		</>
																	)
																)}
															</>
														)}
													</>
												) : (
													<>
														<Grid
															container
															alignItems='center'
															justifyContent='center'
														>
															<CircularProgress
																size='20px'
																style={{ margin: '5%' }}
															/>
														</Grid>
													</>
												)}
											</Box>
										</Box>
									</Grid>

									{/* Edit a Ticket Dialog */}
									{editDialogOpen && (
										<CreateUpdateTicket
											open={editDialogOpen}
											title={'Edit Ticket'}
											primaryButtonText={'Update'}
											primaryAction={(updateTicketPayload: any) => {
												_handleUpdateTicket(updateTicketPayload)
											}}
											secondaryButtonText={'Cancel'}
											secondaryAction={() => {
												handleCloseEditTicket()
											}}
											fromUpdate={true}
											updateTicketDetails={ticketDetails}
											plantId={ticketDetails?.plant_id?._id}
										/>
									)}

									{/* Open Attechment Dialog */}
									<Dialog
										open={openAttechment}
										aria-labelledby='form-dialog-title'
										maxWidth={false}
										className={classes.imageViewerDialog}
									>
										<div className={classes.attachmentsDialogWrapper}>
											<div className='contentWrapper'>
												<div className={classes.imagePreviewBody}>
													<DialogTitle>Attachments</DialogTitle>
													<CloseIcon
														onClick={handleCloseAttechment}
														className={classes.row}
													/>
												</div>

												<Divider />
											</div>

											{chatroomResponse?.data?.messages?.map((item: any) => (
												<React.Fragment key={item?.id}>
													{item?.text?.blocks &&
														item?.text?.entityMap &&
														Object.values(item?.text?.entityMap)?.map(
															(entity: any, entityIndex: any) => {
																const entityType = entity?.type
																return entityType !== 'MENTION' ? (
																	chatroomAttachments(item, entity, entityIndex)
																) : (
																	<React.Fragment
																		key={entity?.id}
																	></React.Fragment>
																)
															}
														)}
												</React.Fragment>
											))}
										</div>
									</Dialog>

									{/* Image overview dialog */}
									<ImageViewer
										setShowModal={setShowModalImage}
										showModal={showModalImage}
										attachmentURL={imageUrl}
										setAttahmentURL={setImageURL}
									/>
									{/* Status Confirm Dialog*/}
									{statusChangeObject.commentDialog && (
										<CommentDialog
											open={statusChangeObject.commentDialog}
											onConfirm={async (rawValue: any): Promise<void> => {
												await _updateTicketStatus()
												await updateStatusAndComment(rawValue)
												setStatusChangeObject({
													...statusChangeObject,
													commentDialog: false,
													ticketStatus: null
												})
											}}
											onClose={() => {
												setStatusChangeObject({
													...statusChangeObject,
													commentDialog: false,
													ticketStatus: null
												})
											}}
										/>
									)}

									{statusChangeObject.logilityDilaog && (
										<Logility
											open={statusChangeObject.logilityDilaog}
											onConfirm={async (
												logilityDetails: any
											): Promise<void> => {
												const response = await _updateTicketStatus(
													logilityDetails?.logility
												)
												if (response) {
													await updateStatusAndComment(
														logilityDetails?.rawValue
													)
												}
												setStatusChangeObject({
													...statusChangeObject,
													logilityDilaog: false,
													ticketStatus: null
												})
											}}
											onClose={() => {
												setStatusChangeObject({
													...statusChangeObject,
													logilityDilaog: false,
													ticketStatus: null
												})
											}}
											plant_downtime_start_date={dayjs(
												moment(
													ticketDetails?.dt_start_date?.split('T')[0]
												).format('MM-DD-YYYY')
											)}
											plant_downtime_end_date={dayjs(
												moment(
													ticketDetails?.dt_end_date?.split('T')[0]
												).format('MM-DD-YYYY')
											)}
											changeStatus={statusChangeObject.ticketStatus}
											currentStatus={ticketDetails?.dt_ticket_status}
										/>
									)}

									{/* Add New Comment Dialog*/}
									<Dialog
										open={addNewComment || commentEdit}
										aria-labelledby='form-dialog-title'
										maxWidth={false}
										className={classes.imageViewerDialog}
									>
										<div
											style={{
												width: '1000px',
												padding: '24px',
												display: 'flex',
												justifyContent: 'center',
												flexDirection: 'column',
												alignItems: 'center',
												background: '232A58'
											}}
										>
											<div className='contentWrapper'>
												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'space-between'
													}}
												>
													<DialogTitle>Comments</DialogTitle>
													<CloseIcon onClick={_handleCloseAddNewComment} />
												</div>
												<Divider />
												<RichTextEditor
													handleSubmit={_handleCreateUpdateComment}
													editorType='closeTicket'
													text={commentEdit ? commentDataForAction : {}}
													usecase='downtime-request'
												/>
											</div>
										</div>
									</Dialog>

									{/* Ticket Deleted confirmation */}

									<ConfirmAlertDialog
										open={ticketDeleteDialog}
										title='Ticket Deleted'
										secondaryTitle={
											'The ticket has been deleted in the Arch System'
										}
										primaryButtonText={'Done'}
										primaryAcion={() => {
											handleCloseTicketDeleteDialog()
											navigate(-1)
										}}
										primaryButtonClass={'doneBtn'}
										secondaryButtonText={''}
										secondaryAction={() => {
											handleCloseTicketDeleteDialog()
										}}
										primaryListTitle={`Ticket ID: ${ticketDetails?.dt_ticket_alias}`}
									/>

									{/* Ticket Delete confirmation */}
									<ConfirmAlertDialog
										open={ticketDeleteConfirmation}
										title='Delete Ticket?'
										secondaryTitle={
											'The ticket has been delete in the Arch System'
										}
										primaryButtonText={'Delete'}
										primaryAcion={() => {
											handleDeleteTicket()
										}}
										primaryButtonClass={'deleteBtn'}
										secondaryButtonText={'Cancel'}
										secondaryAction={() => {
											handleCloseTicketDeleteConfirmation()
										}}
										primaryListTitle={`Ticket ID: ${ticketDetails?.dt_ticket_alias}`}
									/>

									{/* Delete ticket comment confirm alert */}
									<ConfirmAlertDialog
										open={commentDelete}
										title='Delete Comment ?'
										secondaryTitle={
											'The Comment will be deleted from the Arch System'
										}
										primaryButtonText={'Delete'}
										primaryAcion={() => {
											setCommentDelete(false)
											_handleCommentDelete()
										}}
										primaryButtonClass={'deleteBtn'}
										secondaryButtonText={'Cancel'}
										secondaryAction={() => {
											setCommentDelete(false)
											setAnchorElCommentDotPopUp(null)
										}}
									/>
								</>
							)}
						</Grid>
					</Box>
				</div>
			)}
		</>
	)
}

export default TicketDetailsSupplyChain

