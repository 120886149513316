import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { MsalProvider } from '@azure/msal-react'
import { registerLicense } from '@syncfusion/ej2-base'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import store from './Redux/store'
import { SidebarProvider } from './contexts/SidebarContext'
import { msConfig } from './Services/authService'
import AuthAD from './Auth'
import OEMSignOffPage from './pages/master/UsecaseDashboard/SATSignOff/OEMDocUploadPage'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import App from './App'
import { env } from './env'

registerLicense(env.eam_syncfusion_license)

const urlSegments = window.location.hash.split('/')
const url = urlSegments.slice(1, urlSegments.length).join('/')

const qc = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			// refetchOnReconnect: false,
			refetchOnMount: true
		}
	}
})

const container = document.getElementById('root')
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

root.render(
	<HelmetProvider>
		<Provider store={store}>
			<SidebarProvider>
				<HashRouter>
					{url.includes('sat/upload-docs/') ? (
						<OEMSignOffPage />
					) : (
						<MsalProvider instance={msConfig}>
							<AuthAD>
								<QueryClientProvider client={qc}>
									<App />
								</QueryClientProvider>
							</AuthAD>
						</MsalProvider>
					)}
				</HashRouter>
			</SidebarProvider>
		</Provider>
	</HelmetProvider>
)

