/*eslint no-useless-catch: "error"*/
import axios from '../../Utils/AxiosConfig/axiosConfigSAT'
// import axios from "axios";
import { put, call, takeEvery } from 'redux-saga/effects'
import {
	LOAD_SATKPIDATA,
	LOAD_SATKPIDATA_SUCCESS,
	LOAD_SATKPIDATA_ERROR
} from '../Types/satKpiData.constants'

const getSATKPIData = async (requestBody: any) => {
	// try {
	const res = await axios.post(`GetSATKPIData`, requestBody)
	return res.data
	// } catch (error) {
	// 	throw error
	// }
}

interface countsRecord {
	equipmentname: string
	equipmentbizid: string
	timestamp: string
	GoodProducedCount: number
	RejectCount: number
	TotalProducedCount: number
}

function calculateCumulativeCounts(inputData: countsRecord[]): countsRecord[] {
	const outputData: countsRecord[] = []

	let currentMachine: string | null = null
	let cumulativeGoodProducedCount = 0
	let cumulativeRejectCount = 0
	let cumulativeTotalProducedCount = 0

	for (const entry of inputData) {
		if (entry.equipmentname !== currentMachine) {
			// New machine, reset cumulative counts
			currentMachine = entry.equipmentname
			cumulativeGoodProducedCount = entry.GoodProducedCount
			cumulativeRejectCount = entry.RejectCount
			cumulativeTotalProducedCount = entry.TotalProducedCount
		} else {
			// Same machine, update cumulative counts
			cumulativeGoodProducedCount += entry.GoodProducedCount
			cumulativeRejectCount += entry.RejectCount
			cumulativeTotalProducedCount += entry.TotalProducedCount
		}

		// Add the entry with cumulative counts to the output
		outputData.push({
			equipmentname: entry.equipmentname,
			equipmentbizid: entry.equipmentbizid,
			timestamp: entry.timestamp,
			GoodProducedCount: cumulativeGoodProducedCount,
			RejectCount: cumulativeRejectCount,
			TotalProducedCount: cumulativeTotalProducedCount
		})
	}

	return outputData
}

export function* listSATKPIData(action: any): any {
  try {
    const data = yield call(() =>
      getSATKPIData({
        shortname: action.payload.shortname,
        linebizid: action.payload.linebizid,
        linecode: action.payload.linecode,
        daysInfo: action.payload.daysInfo,
        dtCommentsFromConfig: action.payload.dtCommentsFromConfig,
        plantcode: action.payload.plantcode,
        efficiencyEquipments: action.payload.efficiencyEquipments,
        OEMCommentReassignmentEquipments:
          action.payload.OEMCommentReassignmentEquipments,
        EquipmentRejectionMapping: action.payload.EquipmentRejectionMapping,
        PlantTimezone: action.payload.PlantTimezone,
        lineSpeedFromConfig: action.payload.lineSpeedFromConfig,
      }).then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_SATKPIDATA_SUCCESS,
      payload: {
        ...data,
        rawCountsData: [
          ...data.rawCountsData.filter((ele: any) => {
            return (
              !ele.equipmentname.toLowerCase().includes("palletizer") &&
              !ele.equipmentname.toLowerCase().includes("packer")
            );
          }),
          ...data.rawCountsData
            .filter((ele: any) => {
              return ele.equipmentname.toLowerCase().includes("palletizer");
            })
            .map((ele: any) => {
              return {
                ...ele,
                GoodProducedCount:
                  action.payload.palletizerCount === undefined ||
                  action.payload.palletizerCount.length === 0 ||
                  action.payload.palletizerCount.find((dailyCount: any) => {
                    return ele.timestamp.includes(dailyCount.time);
                  }) === undefined ||
                  action.payload.palletizerCount.find((dailyCount: any) => {
                    return ele.timestamp.includes(dailyCount.time);
                  }).count === 0
                    ? ele.GoodProducedCountWithoutCalculation /
                      ele.denominator /
                      86
                    : parseFloat(
                        (
                          ele.GoodProducedCountWithoutCalculation /
                          ele.denominator /
                          parseFloat(
                            action.payload.palletizerCount.find(
                              (dailyCount: any) => {
                                return ele.timestamp.includes(dailyCount.time);
                              }
                            )?.count
                          )
                        ).toFixed(2)
                      ),
                palletizerCountFromSeed: action.payload.palletizerCount.find(
                  (dailyCount: any) => {
                    return ele.timestamp.includes(dailyCount.time);
                  }
                )?.count,
              };
            }),
          ...data.rawCountsData
            .filter((ele: any) => {
              return ele.equipmentname.toLowerCase().includes("packer");
            })
            .map((ele: any) => {
              return {
                ...ele,
                GoodProducedCount:
                  action.payload.packerCount === undefined ||
                  action.payload.packerCount.length === 0 ||
                  action.payload.packerCount.find((dailyCount: any) => {
                    return ele.timestamp.includes(dailyCount.time);
                  }) === undefined ||
                  action.payload.packerCount.find((dailyCount: any) => {
                    return ele.timestamp.includes(dailyCount.time);
                  }).count === 0
                    ? ele.GoodProducedCountWithoutCalculation / ele.denominator
                    : parseFloat(
                        (
                          ele.GoodProducedCountWithoutCalculation /
                          ele.denominator /
                          parseFloat(
                            action.payload.packerCount.find(
                              (dailyCount: any) => {
                                return ele.timestamp.includes(dailyCount.time);
                              }
                            )?.count
                          )
                        ).toFixed(2)
                      ),
                packerCountFromSeed: action.payload.packerCount.find(
                  (dailyCount: any) => {
                    return ele.timestamp.includes(dailyCount.time);
                  }
                )?.count,
              };
            }),
        ],
        cumulativeRawCountsData:
          action.payload.palletizerCount === undefined ||
          action.payload.palletizerCount.length === 0
            ? calculateCumulativeCounts([...data.rawCountsData])
            : calculateCumulativeCounts([
                ...data.rawCountsData.filter((ele: any) => {
                  return !ele.equipmentname
                    .toLowerCase()
                    .includes("palletizer");
                }),
                ...data.rawCountsData
                  .filter((ele: any) => {
                    return ele.equipmentname
                      .toLowerCase()
                      .includes("palletizer");
                  })
                  .map((ele: any) => {
                    return {
                      ...ele,
                      GoodProducedCount:
                        action.payload.palletizerCount.find(
                          (dailyCount: any) => {
                            return ele.timestamp.includes(dailyCount.time);
                          }
                        ) === undefined ||
                        action.payload.palletizerCount.find(
                          (dailyCount: any) => {
                            return ele.timestamp.includes(dailyCount.time);
                          }
                        ).count === 0
                          ? ele.GoodProducedCountWithoutCalculation /
                            ele.denominator /
                            86
                          : parseFloat(
                              (
                                ele.GoodProducedCountWithoutCalculation /
                                ele.denominator /
                                parseFloat(
                                  action.payload.palletizerCount.find(
                                    (dailyCount: any) => {
                                      return ele.timestamp.includes(
                                        dailyCount.time
                                      );
                                    }
                                  )?.count
                                )
                              ).toFixed(2)
                            ),
                      palletizerCountFromSeed:
                        action.payload.palletizerCount.find(
                          (dailyCount: any) => {
                            return ele.timestamp.includes(dailyCount.time);
                          }
                        )?.count,
                    };
                  }),
              ]),
      },
    });
  } catch (error) {
    yield put({
      type: LOAD_SATKPIDATA_ERROR,
      payload: null,
    });
  }
}

export function* listSatKpiDataSaga() {
  yield takeEvery(LOAD_SATKPIDATA, listSATKPIData);
}
