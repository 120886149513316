import { clone, cloneDeep, get, set, isEmpty } from 'lodash'
import React, { useState } from 'react'
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField
} from '@mui/material'
import {
	ALPHANUMERIC_VALIDATION_REGEX,
	COLOR_CODE_REGEX
} from '../../Utils/SelfServiceHelper'

const CCPsMonitored = (props: any) => {
	const { items, onSeqChange, onError, resetListItems } = props
	const [selectedStatus, selectStatus] = useState('')
	const [CCPsMonitoredError, setCCPsMonitoredError] = useState<any>({})
	const [StatusError, setStatusError] = useState<any>({})

	const statusOptions = items ? Object.keys(items) : [];

	const checkErrorUnderCurrentSelection = () => {
		let isErrorPresent = false
		if (selectedStatus === 'CCPsMonitored') {
			Object.keys(CCPsMonitoredError).forEach(key => {
				if (CCPsMonitoredError[key]) isErrorPresent = true
			})
		}
		if (selectedStatus === 'CIPStatus' || selectedStatus === 'ProdStatus') {
			Object.keys(StatusError).forEach(indexKey => {
				Object.keys(StatusError[indexKey]).forEach(key => {
					if (isEmpty(StatusError[indexKey][key])) isErrorPresent = true
				})
			})
		}
		return isErrorPresent
	}

	const handleItemValueChange = (
		data: any,
		editIndex: number,
		updatedKey = ''
	) => {
		const clonedUpdatedItems = cloneDeep(items)

		const statusCondition =
			selectedStatus === 'CIPStatus' || selectedStatus === 'ProdStatus'
		const displayValueCondition =
			updatedKey === 'displayValue' &&
			(isEmpty(data[updatedKey]) ||
				!ALPHANUMERIC_VALIDATION_REGEX.test(data[updatedKey]))
		const displayColorCondition =
			updatedKey === 'displayColor' &&
			(isEmpty(data[updatedKey]) || !COLOR_CODE_REGEX.test(data[updatedKey]))
		const valueCondition = updatedKey === 'value' && isEmpty(data[updatedKey])

		if (
			selectedStatus === 'CCPsMonitored' &&
			(!data.ccp_count || data.ccp_count <= 0)
		) {
			const clonedError = clone(CCPsMonitoredError)
			setCCPsMonitoredError({
				...clonedError,
				[data.asset]: 'Invalid value'
			})
			onError(true)
		} else if (
			statusCondition &&
			(displayValueCondition || displayColorCondition || valueCondition)
		) {
			if (displayValueCondition) {
				const clonedError = clone(StatusError)
				setStatusError({
					...clonedError,
					[editIndex]: {
						...clonedError[editIndex],
						displayValue: 'Invalid value'
					}
				})
				onError(true)
			} else if (displayColorCondition) {
				const clonedError = clone(StatusError)
				setStatusError({
					...clonedError,
					[editIndex]: {
						...clonedError[editIndex],
						displayColor: 'Invalid value'
					}
				})
				onError(true)
			} else if (valueCondition) {
				const clonedError = clone(StatusError)
				setStatusError({
					...clonedError,
					[editIndex]: {
						...clonedError[editIndex],
						value: 'Invalid value'
					}
				})

				onError(true)
			}
		} else {
			set(
				clonedUpdatedItems,
				`${selectedStatus}`,
				get(clonedUpdatedItems, `${selectedStatus}`, []).map(
					(item: any, index: number) => {
						if (editIndex === index) {
							return data
						}
						return item
					}
				)
			)
			const clonedCCPError = clone(CCPsMonitoredError)

			setCCPsMonitoredError({
				...clonedCCPError,
				[data.asset]: ''
			})
			const clonedStatusError = clone(StatusError)
			delete clonedStatusError[editIndex]

			setStatusError({
				...clonedStatusError
			})

			const isErrorPresent = checkErrorUnderCurrentSelection()

			onSeqChange(clonedUpdatedItems, !isErrorPresent)
		}
	}


	return (
    (items) ?
		<div className='selfConfigComponent' style={{ width: '470px' }}>
			<FormControl
				variant='outlined'
				fullWidth
				size='medium'
				sx={{ marginTop: '16px' }}
			>
				<InputLabel id='demo-multiple-name-label'>Select Status</InputLabel>
				<Select
					labelId='demo-multiple-name-label'
					id='demo-multiple-name'
					// multiple
					value={selectedStatus}
					onChange={(event: any) => {
						setCCPsMonitoredError({})
						setStatusError({})
						selectStatus(event.target.value)
						resetListItems()
					}}
					input={<OutlinedInput label='Select Status' />}
				>
					{statusOptions.map((option: string) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{selectedStatus === 'CCPsMonitored'
				? get(items, `${selectedStatus}`, []).map(
						(item: any, index: number) => (
							<React.Fragment key={`${item?.asset}`}>
								<TextField
									defaultValue={item.ccp_count}
									label={item.asset}
									type='number'
									fullWidth
									onChange={(event: any) => {
										const sendData = {
											...item,
											ccp_count: parseInt(event.target.value)
										}
										handleItemValueChange(sendData, index)
									}}
									sx={{
										marginTop: '16px'
									}}
									error={!!CCPsMonitoredError[item.asset]}
									helperText={CCPsMonitoredError[item.asset] || ''}
								/>
							</React.Fragment>
						)
					)
				: null}
			{selectedStatus === 'CIPStatus' || selectedStatus === 'ProdStatus'
				? get(items, `${selectedStatus}`, []).map(
						(item: any, index: number) => (
							<React.Fragment key={`${item?.asset}`}>
								<Grid
									container
									style={{ margin: '0em 0em', padding: '0em' }}
									spacing={2}
									key={`${selectedStatus}`}
								>
									<Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
										<TextField
											defaultValue={item.displayValue}
											label='Display Value'
											type='text'
											fullWidth
											onChange={(event: any) => {
												const sendData = {
													...item,
													displayValue: event.target.value
												}
												handleItemValueChange(sendData, index, 'displayValue')
											}}
											sx={{
												marginTop: '16px'
											}}
											error={!!StatusError[index]?.displayValue}
											helperText={StatusError[index]?.displayValue || ''}
										/>
									</Grid>
									<Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
										<TextField
											defaultValue={item.displayColor}
											label='Display Color'
											type='text'
											fullWidth
											onChange={(event: any) => {
												const sendData = {
													...item,
													displayColor: event.target.value
												}
												handleItemValueChange(sendData, index, 'displayColor')
											}}
											sx={{
												marginTop: '16px'
											}}
											error={!!StatusError[index]?.displayColor}
											helperText={StatusError[index]?.displayColor || ''}
										/>
									</Grid>
									<Grid style={{ padding: '8px 16px 0 0' }} item xs={4}>
										<TextField
											defaultValue={item.value}
											label='Value'
											type='text'
											fullWidth
											onChange={(event: any) => {
												const sendData = {
													...item,
													value: event.target.value
												}
												handleItemValueChange(sendData, index, 'value')
											}}
											sx={{
												marginTop: '16px'
											}}
											error={!!StatusError[index]?.value}
											helperText={StatusError[index]?.value || ''}
										/>
									</Grid>
								</Grid>
							</React.Fragment>
						)
					)
				: null}
		</div> : <div style={{padding: "25px 5px 10px", height: "24px", width: "auto", display: "flex", alignItems: "center" , fontSize: "12px"}}>Data not found</div>
	)
}

export default CCPsMonitored
