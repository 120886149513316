import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { Plant } from '../../models/Types_Interfaces'
import { useNavigate } from 'react-router-dom'
import {
	getFormatedDate,
	getTimeZoneTimeStamp
} from '../../Utils/UtilFunctions/timeZoneFormatter'
import { RED,ORANGE,GREEN } from '../../Utils/UtilFunctions/constant'

import { ReactComponent as DangerIcon } from '../../Icons/AlertCancelIcon.svg'
import { ReactComponent as AlertIcon } from '../../Icons/AlertIcon.svg'
import { ReactComponent as TickIcon } from '../../Icons/AlertTickIcon.svg'

function LGVPopUp(props: any) {
	const ticketsList = useSelector((state: any) => state.ticketsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const messagesList = useSelector((state: any) => state.messagesList)
	const navigate = useNavigate()

	const alertType = props?.alert?.message_data?.alert_type
	let healthStatus = props?.alert?.message_data?.CurrentValue
	if (alertType === 'LGV PLS Sensors') {
		healthStatus = props?.alert?.message_data?.CurrentHealth
	}

	const navQualityThresholdCheck = (healthStatus: number) => {
		const status =
			healthStatus >= 85
				? GREEN
				: healthStatus >= 60 && healthStatus < 85
					? ORANGE
					: RED
		const style =
			healthStatus >= 85
				? `3px solid ${GREEN}`
				: healthStatus >= 60 && healthStatus < 85
					? `3px solid ${ORANGE}`
					: `3px solid ${RED}`
		const icon =
			healthStatus >= 85 ? (
				<TickIcon />
			) : healthStatus >= 60 && healthStatus < 85 ? (
				<AlertIcon />
			) : (
				<DangerIcon />
			)
		return { status, style, icon }
	}

	const plsSensorThresholdCheck = (healthStatus: any) => {
		const status =
			healthStatus === 'Danger'
				? RED
				: healthStatus === 'Monitor'
					? ORANGE
					: GREEN
		const style =
			healthStatus === 'Danger'
				? `3px solid ${RED}`
				: healthStatus === 'Monitor'
					? `3px solid ${ORANGE}`
					: `3px solid ${GREEN}`
		const icon =
			healthStatus === 'Danger' ? (
				<DangerIcon />
			) : healthStatus === 'Monitor' ? (
				<AlertIcon />
			) : (
				<TickIcon />
			)

		return { status, style, icon }
	}

	const hydInvThresholdCheck = (healthStatus: number) => {
		const status =
			healthStatus === 0 ? RED : healthStatus === 5 ? ORANGE : GREEN
		const style =
			healthStatus === 0
				? `3px solid ${RED}`
				: healthStatus === 5
					? `3px solid ${ORANGE}`
					: `3px solid ${GREEN}`
		const icon =
			healthStatus === 0 ? (
				<DangerIcon />
			) : healthStatus === 5 ? (
				<AlertIcon />
			) : (
				<TickIcon />
			)

		return { status, style, icon }
	}

	const getThresholdRange = (healthStatus: any, alertType: string) => {
		let thresholdDataObj: any
		switch (alertType) {
			case 'LGV Navigation Quality':
				thresholdDataObj = navQualityThresholdCheck(healthStatus)
				break
			case 'LGV PLS Sensors':
				thresholdDataObj = plsSensorThresholdCheck(healthStatus)
				break
			case 'LGV Hydraulic System Health':
			case 'LGV Inverter System Health':
				thresholdDataObj = hydInvThresholdCheck(healthStatus)
				break
		}
		return thresholdDataObj
	}

	return (
		<Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
			<Fade in={props.open}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						position: 'absolute' as const,
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: window?.innerWidth < 400 ? '300px' : '550px',
						boxShadow: '24',
						padding: '16px 40px',
						color: 'white',
						borderRadius: '12px',
						backgroundColor: getThresholdRange(healthStatus, alertType)?.status,
						border: getThresholdRange(healthStatus, alertType)?.style
					}}
				>
					<div
						style={{
							marginTop: '20px'
						}}
					>
						{getThresholdRange(healthStatus, alertType)?.icon}
					</div>

					<div
						style={{
							marginBottom: '5px',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<h5 style={{ fontSize: '1.2rem' }}>
							{plantsList.plants.find((plant: Plant) => {
								return plant._id === props?.alert?.plant_id
							}) !== undefined
								? plantsList.plants.find((plant: Plant) => {
										return plant._id === props?.alert?.plant_id
									}).plant_name
								: 'Plant not found'}
						</h5>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center'
						}}
					>
						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '100%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Machine Name
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{props?.alert?.asset_alias ?? 'NA'}
							</p>
						</div>
					</div>

					<div
						style={{
							backgroundColor: 'rgba(255, 255, 255, 0.7)',
							color: 'black',
							padding: '12px',
							borderRadius: '3px',
							boxShadow: '1px 1px 0px 0px #000000',
							width: '98%',
							margin: '5px'
						}}
					>
						<h5
							style={{
								fontWeight: '800',
								color: '#000',
								fontSize: '12px'
							}}
						>
							Description
						</h5>

						<p>
							{props.alert.message_data !== undefined &&
								`On  ${
									props.alert?.timestamp !== undefined &&
									props.alert?.timestamp !== null &&
									getFormatedDate(
										plantsList.plants.find((plant: any) => {
											return plant._id === props.alert.plant_id
										})?.plant_timezone,
										getTimeZoneTimeStamp(
											plantsList.plants.find((plant: any) => {
												return plant._id === props.alert.plant_id
											})?.plant_timezone,
											props.alert?.timestamp
										),
										'MM/DD/yyyy'
									)
								} at ${
									props.alert?.timestamp !== undefined &&
									props.alert?.timestamp !== null &&
									getFormatedDate(
										plantsList.plants.find((plant: any) => {
											return plant._id === props?.alert?.plant_id
										})?.plant_timezone,
										getTimeZoneTimeStamp(
											plantsList.plants.find((plant: any) => {
												return plant._id === props?.alert?.plant_id
											})?.plant_timezone,
											props.alert?.timestamp
										),
										'hh:mm A z'
									)
								}, the health level is ${
									healthStatus === 10
										? 'Good'
										: healthStatus === 5
											? 'Warning'
											: 'Critical'
								}`}
						</p>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-evenly'
						}}
					>
						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.handleClose()
							}}
							type='button'
						>
							Close
						</button>

						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.setOpen(false)
								navigate(
									`/maintenance/ticket-details/${props?.alert?.ticket_id}/${props?.alert?.message_id}`,
									{
										replace: true
									}
								)
							}}
							disabled={ticketsList.loading || messagesList.loading}
							type='button'
						>
							{ticketsList.loading ||
								(messagesList.loading && (
									<CircularProgress
										sx={{
											color: 'black',
											height: '1px',
											margin: '0px 20px 0px 10px'
										}}
										size={18}
									/>
								))}
							Go to Ticket
						</button>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}

export default LGVPopUp
