import { experimentalStyled } from "@material-ui/core/styles";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AssetIndicator = experimentalStyled(Box)(
  ({ theme }) => `
            background-color:#232A58;
            padding:10px 20px;
            color: #FFF;
            width:100%;
            height:100px;
            font-size: ${theme.typography.pxToRem(20)};
            border-radius: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #139BFE;
            box-shadow: 0px 0px 20px rgba(19, 169, 254, 0.25);
            border-radius: 12px;
            position:relative;
      `
);

const AssetBorder = experimentalStyled(Box)(
  () => `
            position: absolute;
            width: 20px;
            top: 0px;
            left: 0px;
            height: 100%;
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
    
            &.MuiBox {
                &-notrunning {
                  background-color: #CC3300;
                }
                &-running {
                    background-color: #99CC33;
                }
                &-intermediate{
                    background-color: #1675e0;
                }
                &-nostatus{
                  background-color: #1675e0;
              }
            }
        
       `
);

function Status(props: any) {
  const navigate = useNavigate();
  const ticketsList = useSelector((state: any) => state.ticketsList);

  // Checking the latest alert for the asset
  const isTicketPresent = ticketsList.tickets
    .filter((data: any) => {
      const isOpen = data.status.some((status: any) => {
        return status.device_status === "Open";
      });
      return (
        data.asset_id === props.asset._id &&
        isOpen &&
        data.usecase_name === "Utilities CBM/PdM"
      );
    })
    .sort(function (a: any, b: any) {
      return a.created_on > b.created_on
        ? -1
        : a.created_on < b.created_on
        ? 1
        : 0;
    })[0];

  return (
    <AssetIndicator>
      <AssetBorder className={`MuiBox-${props.value}`} />
      <Typography
        sx={{
          fontSize: "16px",
          color: "#FFF",
          textAlign: "center",
          lineHeight: "36px",
          fontWeight: "600",
          marginLeft: "20px",
        }}
      >
        {props.asset.asset_alias || props.asset.asset_name}
      </Typography>
      {isTicketPresent !== undefined && isTicketPresent?._id ? (
        <NotificationsActiveTwoToneIcon
          onClick={(event: any) => {
            event.stopPropagation();
            navigate(`/maintenance/ticket-details/${isTicketPresent?._id}`);
          }}
          sx={{ fontSize: "25px" }}
        />
      ) : null}
    </AssetIndicator>
  );
}

export default Status;
