export const LOAD_SEEDS = 'LOAD_SEEDS'
export const LOAD_SEEDS_SUCCESS = 'LOAD_SEEDS_SUCCESS'
export const LOAD_SEEDS_ERROR = 'LOAD_SEEDS_ERROR'
export const UPDATE_SEED = 'UPDATE_SEED'
export const UPDATE_SEED_SUCCESS = 'UPDATE_SEED_SUCCESS'
export const UPDATE_SEED_ERROR = 'UPDATE_SEED_ERROR'
export const UPDATE_ASEPTIC_RECIPE_SEED_DATA = 'UPDATE_ASEPTIC_RECIPE_SEED_DATA'
export const UPDATE_ASEPTIC_RECIPE_SEED_DATA_SUCCESS =
	'UPDATE_ASEPTIC_RECIPE_SEED_DATA_SUCCESS'
export const UPDATE_ASEPTIC_RECIPE_SEED_DATA_ERROR =
	'UPDATE_ASEPTIC_RECIPE_SEED_DATA_ERROR'
