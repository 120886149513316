import { useMsal } from "@azure/msal-react";
import {
  Avatar,
  Box,
  Button,
  Hidden,
  List,
  ListItemText,
  Popover,
  Typography,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { ListItemButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AccountSettings } from "./AccoutSettings.svg";
import { ReactComponent as Signout } from "./signout.svg";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
        width:auto;
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        white-space:nowrap;
`
);

const useStyles = makeStyles(() => ({
  imageBorder: {
    borderRadius: "10%",
  },
  imageBorderOne: {
    borderRadius: "50%",
  },
  nav: {
    "&.MuiList-root.MuiList-root": {
      background: "#2F3B79",
      padding: "10px 5px",
    },
  },
  text: {
    "& .MuiListItemText-primary": {
      color: "#D9D9D9",
      fontSize: 14,
    },
  },
  divider: {
    "&.MuiBox-root": {
      borderBottom: "solid #D9D9D9",
      borderBottomWidth: "thin",
      margin: "8px 5px 0 5px",
    },
  },
  listButton: {
    "&.MuiListItemButton-root": {
      marginBottom: "6px",
    },
  },
}));

function HeaderUserbox() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { accounts, instance } = useMsal();

  const user = {
    name: accounts[0]?.name,
    avatar: "",
    mailId: accounts[0]?.username,
    jobtitle: "",
  };

  // const user = {
  //   name: data.account.name,
  //   avatar: "/static/images/avatars/1.jpg",
  //   jobtitle:
  //     data.account.idToken.roles === undefined
  //       ? ""
  //       : data.account.idToken.roles[0],
  // };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logout = () => {
    instance.logoutRedirect();
  };

  const usersList = useSelector((state: any) => state.usersList);
  const userListImages = useSelector((state: any) => state.userListImages);
  const [currentUser, setCurrentUser] = useState<any>("");

  useEffect(() => {
    if (userListImages?.loading === false) {
      const currentUser = userListImages.users?.find((data: any) => {
        return data._id === usersList.currentUser?._id;
      });
      setCurrentUser(currentUser);
    }
  }, [userListImages]);

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Badge
          color="error"
          variant="dot"
          invisible={!(usersList.currentUserPermissions.includes("ARCH_ADMIN") && usersList.pendingApprovalUsers.length)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}          
          sx={{
            "& .MuiBadge-badge": {
              marginBottom: '6px',
              marginRight: '6px',
            },
          }}
        >
          {currentUser?.userImage === undefined ||
          currentUser?.userImage === null ? (
            <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          ) : (
            <img
              src={currentUser.userImage}
              className={`kt-widget__img ${classes.imageBorder}`}
              height="50px"
              alt="user-logo"
            />
          )}
        </Badge>

        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            {/* <UserBoxDescription variant="body2">
                    {user.jobtitle}
                  </UserBoxDescription> */}
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>

      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        style={{ zIndex: "1305" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* <MenuUserBox sx={{ minWidth: 210 }}> */}
        {/* {usersList.currentUser?.userImage === undefined ||
                usersList.currentUser?.userImage === null ? (
                  <Avatar variant="rounded" alt={user.name} src={user.avatar} />
                ) : (
                  <img
                    src={usersList.currentUser?.userImage}
                    className={`kt-widget__img ${classes.imageBorderOne}`}
                    height="75px"
                    alt="user-logo"
                  />
                )} */}
        {/* <UserBoxText> */}
        {/* <UserBoxLabel variant="body1">{user.name}</UserBoxLabel> */}
        {/* <UserBoxDescription variant="body2">
                    {user.jobtitle}
                  </UserBoxDescription> */}
        {/* </UserBoxText> */}

        {/* <RolesList>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: "13px", color: "#6FCF97" }}
                    component="h5"
                  >
                    Roles
                  </Typography>
                  {usersList.currentUserPermissions?.map((data: any) => {
                    return (
                      <Typography gutterBottom sx={{ color: "#D9D9D9" }}>
                        {data}
                      </Typography>
                    );
                  })}
                </RolesList> */}

        {/* <Box className={classes.divider}></Box> */}
        {/* </MenuUserBox> */}

        <List component="nav" className={classes.nav}>
          {/* <ListItem button to="/user/profile/details" component={NavLink}>
                  <AccountBoxTwoToneIcon fontSize="small" />
                  <ListItemText primary="My Profile" />
                </ListItem>
                <ListItem button to="/user/messenger" component={NavLink}>
                  <InboxTwoToneIcon fontSize="small" />
                  <ListItemText primary="Messenger" />
                </ListItem>
                <ListItem
                  button
                  to="/user/profile/settings"
                  component={NavLink}
                >
                  <AccountTreeTwoToneIcon fontSize="small" />
                  <ListItemText primary="Account Settings" />
                </ListItem> */}
          {/* <ListItemButton
            component="button"
            onClick={() => {
              window.location.href = env.baseURL_monarch.substring(
                0,
                env.baseURL_monarch.indexOf("/api/")
              );
              // navigate("https://monarchnb-development.azurewebsites.net/");
              handleClose();
            }}
            className={classes.listButton}
          >
            <AccountSettings style={{ marginRight: "10px" }} />
            <ListItemText primary="Go to Monarch" className={classes.text} />
          </ListItemButton> */}
          <ListItemButton
            component="button"
            onClick={() => {
              navigate("/management/profile");
              handleClose();
            }}
            className={classes.listButton}
          >
            <AccountSettings style={{ marginRight: "10px" }} />
            <ListItemText primary="Account Settings" className={classes.text} />
          </ListItemButton>


          {(usersList.currentUserPermissions.includes("ARCH_ADMIN") || usersList.currentUserPermissions.includes('ARCH_DEVELOPER')) && 
          <ListItemButton component="button" onClick={() => {
            navigate("/management/admin-logs");
            handleClose();
          }}
            className={classes.listButton}>
              <AutoStoriesOutlinedIcon style={{ marginRight: "10px" }}/>
            <ListItemText primary="Activity Logs" className={classes.text} />
          </ListItemButton>}

          <ListItemButton
            component="button"
            onClick={() => {
              const toEmail = "DigitalTechnologyTeam@niagarawater.com";
              const subject = "ARCH - Questions/ Feedback";
              const mailtoUrl = `mailto:${toEmail}?subject=${encodeURIComponent(
                subject
              )}&body=`;
              window.location.href = mailtoUrl;
              handleClose();
            }}
            className={classes.listButton}
          >
            <ContactSupportIcon style={{ marginRight: "10px" }} />
            <ListItemText primary="Contact Us" className={classes.text} />
          </ListItemButton>

          <ListItemButton
            component="button"
            onClick={logout}
            className={classes.listButton}
          >
            <Signout style={{ marginRight: "10px" }} />
            <ListItemText primary="Sign out" className={classes.text} />
          </ListItemButton>

          
        </List>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
