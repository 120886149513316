import {
  Icon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper
} from "@mui/material";
import { ReactElement } from "react";
import { dashboardStyles } from "../DowntimeLandingPageStyles";

export interface popOverItem {
  label: string;
  key: string;
  icon?: any;
  disabled?: boolean;
}

export interface popOverItemsInferface {
  popOverItmes: Array<popOverItem>;
  popOverItemAction(popOverItem: any): void;
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  closePopUp(): void;
}

const PopOverItems = (props: popOverItemsInferface): ReactElement => {
  const classes = dashboardStyles();
  const renderEachPopOverItem = (item: popOverItem): ReactElement => {
    return (
      <MenuItem
        onClick={() => {
          if (typeof props.popOverItemAction === "function") {
            props.popOverItemAction(item);
          }
        }}
        disabled={item?.disabled || false}
      >
        {item.icon && (
          <Icon className={classes.popOverIconMargin}>{item.icon}</Icon>
        )}
        <ListItemText>{item.label}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.closePopUp}
        TransitionProps={{
          timeout: 0,
        }}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList
          className={classes.popOverMenuList}
          style={{ padding: "0px" }}
        >
          {props.popOverItmes.map((item: popOverItem) => {
            return renderEachPopOverItem(item);
          })}
        </MenuList>
      </Menu>
    </Paper>
  );
};

export default PopOverItems;
