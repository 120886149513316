import { Box, Hidden, IconButton, Tooltip } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import MenuTwoToneIcon from "@material-ui/icons/MenuTwoTone";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SidebarContext } from "../../contexts/SidebarContext";
import { env } from "../../env";
import AccountPermission from "../../pages/master/User/accountPermission/accountPermission";
import Banner from "../Alert/Banner";
import Logo from "./../Logo";
import HeaderUserbox from "./../Userbox";
import HeaderActions from "./Actions";

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 1300;
        background-color: #232A58;
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
           left:0;
            width: auto;
        }
`
);

const SlandingLine = experimentalStyled(Box)(
  ({ theme }) => `
    width: 68px;
    height: 47px;
    border-bottom: 3px solid #F18A00;
    -webkit-transform:
      translateY(20px)
      translateX(-18px)
      rotate(-67deg);
    position: absolute;
    top: -24px;
    left: 95px;
    z-index: 10;
`
);

const SlandingLineMobile = experimentalStyled(Box)(
  ({ theme }) => `
    width: 68px;
    height: 47px;
    border-bottom: 3px solid #F18A00;
    -webkit-transform:
      translateY(20px)
      translateX(-18px)
      rotate(-67deg);
    position: absolute;
    top: -24px;
    left: 95px;
    z-index: 10;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      left:95px;
   }
`
);

const LogoTextWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(0.2)};
        text-align:center;
        margin-left:5px;
        margin-bottom:30px;
`
);

const VersionBadge = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.palette.primary.main};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
);

const LogoText = experimentalStyled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(20)};
        font-weight: ${theme.typography.fontWeightBold};
        line-height:2.5
`
);

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const useStyles = makeStyles(() => ({
  centerDiv: {
    "&.MuiBox-root": {
      width: "100%",
      height: "100%",
      backgroundColor: "#293368",
      position: "relative",

      "&:before": {
        content: "' '",
        position: "absolute",
        top: "0",
        left: "0",
        width: "0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "60px 25px 0px 0px",
        borderColor: "#232A58 transparent transparent transparent",
      },

      "&:after": {
        content: "' '",
        position: "absolute",
        bottom: "0",
        right: "0",
        width: "0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "0 0 55px 25px",
        borderColor: "transparent transparent #232A58 transparent",
      },
    },
  },
}));

function Header() {
  // left: ${theme.sidebar.width};
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const bannerState = useSelector((state: any) => state.banner);
  const classes = useStyles();

  return (
    <HeaderWrapper display="flex" alignItems="center" className="hide-print">
      <Box
        display="flex"
        alignItems="center"
        sx={{
          marginLeft: { md: "0px" },
          width: { xs: "44%", sm: "unset" },
        }}
      >
        {/* Moved Hamburger menu to start */}
        {/* <Hidden lgUp>
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? (
              <Tooltip arrow title="Menu">
                <MenuTwoToneIcon />
              </Tooltip>
            ) : (
              <Tooltip arrow title="Close">
                <CloseTwoToneIcon />
              </Tooltip>
            )}
          </IconButton>
        </Hidden> */}
        {/* <Hidden mdDown> */}
          <Logo />
          <SlandingLine></SlandingLine>
        {/* </Hidden> */}
        {/* <Hidden mdUp>
          <span style={{ marginLeft: "-10px" }}>
            <Logo />
          </span>
          <span>
            <SlandingLineMobile></SlandingLineMobile>
          </span>
        </Hidden> */}

        {/* <Hidden lgUp>
          <Logo />
        </Hidden> */}
      </Box>
      <Box
        className={classes.centerDiv}
        sx={{
          marginLeft: { xs: "-10px", md: "0px" },
          width: { xs: 1 / 3, sm: "unset" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Stack direction="row">
            <LogoWrapper to="/">
              <Hidden mdUp>
                <img
                  src={`${window.location.origin + "/LogoARCH.svg"}`}
                  style={{ width: 70, marginLeft: 20, marginTop: -5 }}
                  alt="MonARCH"
                />
              </Hidden>
              <Hidden mdDown>
                <img
                  src={`${window.location.origin + "/LogoARCH.svg"}`}
                  style={{ width: 85, marginLeft: 25, marginTop: -5 }}
                  alt="MonARCH"
                />
              </Hidden>
            </LogoWrapper>
            <Hidden smDown>
              <LogoTextWrapper>
                <LogoText>
                  {/* ARCH (Niagara Bottling){" "} */}
                  {env.environment !== "PROD" ? (
                    <VersionBadge>{env.environment}</VersionBadge>
                  ) : (
                    <></>
                  )}
                </LogoText>
              </LogoTextWrapper>
            </Hidden>
          </Stack>
          <Banner
            open={bannerState.open}
            message={bannerState.message}
            type={bannerState.type}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{ width: { xs: "28%", sm: "unset" } }}
        justifyContent="space-evenly"
      >
        <AccountPermission
          pageName="notification"
          module="alerts"
          action="read"
        >
          <HeaderActions />
        </AccountPermission>
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
